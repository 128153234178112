import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useAuth } from "@saleor/sdk";
import * as React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Media from "react-responsive";
import ReactSVG from "react-svg";
import updateImg from "../../images/delivery/update.svg";
import profileWhiteImg from "../../images/main/mainProfile.svg";
import hamburgerImg from "../../images/main/menu.svg";
import whiteArrowImg from "../../images/main/whiteArrow.svg";
import profileImg from "../../images/profilemenu.svg";
import profileDelivery from "../../images/delivery.svg";
import * as paths from "../../app/routes";
import createDeliveryMenuStructure from "./deliveryMenuStructure";
import "./scss/index.scss";
import SideBar from "./SideBar";

var NavItem = function NavItem(props) {
  var classNameLabel = props.linkTo === window.location.pathname || "".concat(props.linkTo, "/") === window.location.pathname ? "menuDelivery__nav__container__active" : "menuDelivery__nav__container__link";
  return React.createElement(NavLink, {
    to: props.linkTo,
    className: classNameLabel
  }, React.createElement("span", null, props.label));
};

var NavDelivery = function NavDelivery(_ref) {
  var inProgressAmount = _ref.inProgressAmount,
      finishedAmount = _ref.finishedAmount,
      isLoadingFinishedAmount = _ref.isLoadingFinishedAmount,
      handleInProgressRefetch = _ref.handleInProgressRefetch,
      handleFinishedRefetch = _ref.handleFinishedRefetch;

  var _useAuth = useAuth(),
      user = _useAuth.user,
      signOut = _useAuth.signOut;

  var intl = useIntl();
  var navigate = useNavigate();
  var firstName = user === null || user === void 0 ? void 0 : user.firstName.split(" ");

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showProfileDetail = _React$useState2[0],
      setShowProfileDetail = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      showSideBar = _React$useState4[0],
      setShowSideBar = _React$useState4[1];

  var handleSignOut = React.useCallback(function () {
    signOut();
    navigate(paths.deliveryUrl);
  }, []);
  return React.createElement("div", {
    className: "menuDelivery"
  }, React.createElement("header", {
    className: !window.location.pathname.includes("reports") ? "menuDelivery__nav" : "menuDelivery__navReports"
  }, React.createElement("div", {
    className: "menuDelivery__nav__logo"
  }, React.createElement(ReactSVG, {
    path: hamburgerImg,
    onClick: function onClick() {
      return setShowSideBar(!showSideBar);
    }
  })), React.createElement("div", null, React.createElement("h1", null, !window.location.pathname.includes("reports") ? React.createElement(FormattedMessage, {
    id: "components.MenuDelivery.NavDelivery.3256806584",
    defaultMessage: "Assigned orders"
  }) : React.createElement(FormattedMessage, {
    id: "components.MenuDelivery.NavDelivery.3854876213",
    defaultMessage: "Summary by area"
  }))), React.createElement(Media, {
    minWidth: 850
  }, React.createElement("li", {
    className: "menuDelivery__profile",
    id: "profile"
  }, React.createElement("div", {
    className: "menuDelivery__profile--show",
    onClick: function onClick() {
      setShowProfileDetail(!showProfileDetail);
    }
  }, React.createElement(ReactSVG, {
    path: profileWhiteImg
  }), React.createElement("p", null, firstName[0]), React.createElement("div", {
    className: "menuDelivery__profile--show--arrow"
  }, React.createElement(ReactSVG, {
    path: whiteArrowImg
  }))), showProfileDetail && React.createElement("div", {
    className: "menuDelivery__profile--detail"
  }, React.createElement(ReactSVG, {
    path: profileImg
  }), React.createElement(NavLink, {
    className: "menuDelivery__profile--myProfile",
    to: paths.accountDelivery
  }, React.createElement(FormattedMessage, {
    id: "components.MenuDelivery.NavDelivery.1028973038",
    defaultMessage: "My Profile"
  })), React.createElement("p", {
    className: "menuDelivery__profile--detail--name"
  }, user === null || user === void 0 ? void 0 : user.firstName), React.createElement("p", {
    className: "menuDelivery__profile--detail--number"
  }, user === null || user === void 0 ? void 0 : user.waDaliveryManNumber), React.createElement("button", {
    onClick: handleSignOut
  }, React.createElement(FormattedMessage, {
    id: "components.MenuDelivery.NavDelivery.69198883",
    defaultMessage: "Sign off"
  }))))), React.createElement(Media, {
    maxWidth: 850
  }, React.createElement(NavLink, {
    className: "menuDelivery__iconMyProfile menuDelivery__cartMyProfile",
    to: paths.accountDelivery
  }, React.createElement(ReactSVG, {
    path: profileDelivery
  }))), !window.location.pathname.includes("reports") && React.createElement("div", {
    className: "menuDelivery__nav__container"
  }, React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.MenuDelivery.NavDelivery.2737619734",
      defaultMessage: "In progress"
    }),
    linkTo: paths.baseDeliveryUrl
  }), React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.MenuDelivery.NavDelivery.2967672508",
      defaultMessage: "Completed"
    }),
    linkTo: paths.orderFinishedDeliveryUrl
  }))), (paths.baseDeliveryUrl === window.location.pathname || "".concat(paths.baseDeliveryUrl, "/") === window.location.pathname) && React.createElement("div", null, React.createElement("div", {
    className: "menuDelivery__title"
  }, React.createElement("div", null, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "components.MenuDelivery.NavDelivery.75546233",
    defaultMessage: "Amount"
  }), ": "), React.createElement("span", null, inProgressAmount)), React.createElement(ReactSVG, {
    path: updateImg,
    onClick: function onClick() {
      return handleInProgressRefetch();
    }
  }))), (paths.orderFinishedDeliveryUrl === window.location.pathname || "".concat(paths.orderFinishedDeliveryUrl, "/") === window.location.pathname) && React.createElement("div", null, React.createElement("div", {
    className: "menuDelivery__title"
  }, React.createElement("div", null, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "components.MenuDelivery.NavDelivery.75546233",
    defaultMessage: "Amount"
  }), ": "), isLoadingFinishedAmount ? React.createElement("span", null, React.createElement("div", {
    className: "skeletonPlaceholder"
  })) : React.createElement("span", null, finishedAmount)), React.createElement(ReactSVG, {
    path: updateImg,
    onClick: function onClick() {
      return handleFinishedRefetch();
    }
  }))), showSideBar && React.createElement(SideBar, {
    onHide: function onHide() {
      setShowSideBar(false);
    },
    menuItems: createDeliveryMenuStructure(intl)
  }));
};

export default NavDelivery;