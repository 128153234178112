import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useAuth, useCart, useCheckout, useCurrentTradeDetails } from "@saleor/sdk";
import axios from "axios";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Navigate, useNavigate } from "react-router-dom";
import ReactSVG from "react-svg";
import { Button, CartFooter, CartHeader } from "@components/atoms";
import { TaxedMoney } from "@components/containers";
import { CartRow } from "@components/organisms";
import { Cart } from "@components/templates";
// import { BASE_URL } from "@temp/core/config";
// import { checkoutMessages } from "@temp/intl";
import { commonMessages } from "@temp/intl";
import { AlertObservation } from "@temp/components";
import * as S from "./styles";
import { apiUrl } from "../../../constants";
import { AlertBusinessOpening } from "../../../components/AlertBusinessOpening"; // import { OverlayContext, OverlayTheme, OverlayType } from "../../../components";

import { AlertCheckoutValue } from "../../../components/AlertCheckoutValue";
import { AlertCheckoutAmount } from "../../../components/AlertCheckoutAmount";
import Loader from "../../../components/Loader"; // import { css } from "react-select/lib/components/SingleValue";

var editImg = require("../../../images/edit.svg");

var title = React.createElement(React.Fragment, null, React.createElement(ReactSVG, {
  path: editImg
}), React.createElement("h1", {
  "data-test": "cartPageTitle"
}, React.createElement(FormattedMessage, {
  id: "@next.pages.CartPage.CartPage.1260822068",
  defaultMessage: "Modify Purchase"
}))); // const getShoppingButton = (history: History) => {
//   return (
//     <Button
//       testingContext="cartPageContinueShoppingButton"
//       onClick={() => history.push(BASE_URL)}
//     >
//       <FormattedMessage {...checkoutMessages.continueShopping} />
//     </Button>
//   );
// };

var getCheckoutButton = function getCheckoutButton(refreshHasAttention, flagLoading) {
  return React.createElement(React.Fragment, null, flagLoading ? React.createElement(S.Loader, null, React.createElement(Loader, null)) : React.createElement(React.Fragment, null, React.createElement(S.Waves, null), React.createElement(Button, {
    testingContext: "proceedToCheckoutButton",
    onClick: function onClick() {
      refreshHasAttention();
    }
  }, React.createElement(FormattedMessage, commonMessages.checkout))));
};

var goBackButton = function goBackButton(action) {
  return React.createElement(Button, {
    testingContext: "proceedToGoBackButton",
    onClick: function onClick() {
      return action();
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.pages.CartPage.CartPage.3657540412",
    defaultMessage: "continue shopping"
  }));
};

var cartHeader = React.createElement(CartHeader, null);

var prepareCartFooter = function prepareCartFooter(totalPrice, shippingTaxedPrice, promoTaxedPrice, subtotalPrice) {
  var _promoTaxedPrice$net;

  return React.createElement(CartFooter, {
    subtotalPrice: React.createElement(TaxedMoney, {
      "data-test": "subtotalPrice",
      taxedMoney: subtotalPrice
    }),
    totalPrice: React.createElement(TaxedMoney, {
      "data-test": "totalPrice",
      taxedMoney: totalPrice
    }),
    discountPrice: promoTaxedPrice && (promoTaxedPrice === null || promoTaxedPrice === void 0 ? void 0 : (_promoTaxedPrice$net = promoTaxedPrice.net) === null || _promoTaxedPrice$net === void 0 ? void 0 : _promoTaxedPrice$net.amount) !== 0 && React.createElement(TaxedMoney, {
      "data-test": "discountPrice",
      taxedMoney: promoTaxedPrice
    })
  });
};

var quantityItems = function quantityItems(items) {
  var quantityItemsValue = items === null || items === void 0 ? void 0 : items.length;

  if (items === null || items === void 0 ? void 0 : items.length) {
    return React.createElement("p", null, React.createElement(FormattedMessage, {
      id: "@next.pages.CartPage.CartPage.837049104",
      defaultMessage: "{quantityItemsValue,plural,one {added <span>({quantityItemsValue})</span>} other {Added <cta>({quantityItemsValue})</cta>}}",
      description: "items quantity in cart",
      values: {
        quantityItemsValue: quantityItemsValue,
        span: function span() {
          for (var _len = arguments.length, chunks = new Array(_len), _key = 0; _key < _len; _key++) {
            chunks[_key] = arguments[_key];
          }

          return React.createElement("span", null, chunks);
        },
        cta: function cta() {
          for (var _len2 = arguments.length, chunks = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            chunks[_key2] = arguments[_key2];
          }

          return React.createElement("span", null, chunks);
        }
      }
    }));
  }
};

var generateCart = function generateCart(items, removeItem, updateItem, setShowEdit, setQuantityEdit, setIdEdit, setObservationEdit, observationEdit) {
  return items === null || items === void 0 ? void 0 : items.map(function (_ref, index) {
    var _variant$product, _variant$product2, _variant$product3, _variant$product4, _variant$product4$thu, _variant$attributes;

    var id = _ref.id,
        observation = _ref.observation,
        variant = _ref.variant,
        quantity = _ref.quantity,
        totalPrice = _ref.totalPrice;
    return React.createElement(CartRow, {
      key: id ? "id-".concat(id) : "idx-".concat(index),
      index: index,
      id: (variant === null || variant === void 0 ? void 0 : (_variant$product = variant.product) === null || _variant$product === void 0 ? void 0 : _variant$product.id) || "",
      name: (variant === null || variant === void 0 ? void 0 : (_variant$product2 = variant.product) === null || _variant$product2 === void 0 ? void 0 : _variant$product2.name) || "",
      maxQuantity: variant.quantityAvailable || quantity,
      quantity: quantity,
      observation: observation,
      variantId: variant.id,
      onRemove: function onRemove() {
        return removeItem(variant.id);
      },
      onDataChange: function onDataChange(quantity, observation) {
        updateItem(variant.id, quantity, observation);
      },
      observationEdit: observationEdit,
      setShowEdit: setShowEdit,
      setQuantityEdit: setQuantityEdit,
      setIdEdit: setIdEdit,
      setObservationEdit: setObservationEdit,
      thumbnail: _objectSpread(_objectSpread({}, variant === null || variant === void 0 ? void 0 : (_variant$product3 = variant.product) === null || _variant$product3 === void 0 ? void 0 : _variant$product3.thumbnail), {}, {
        alt: (variant === null || variant === void 0 ? void 0 : (_variant$product4 = variant.product) === null || _variant$product4 === void 0 ? void 0 : (_variant$product4$thu = _variant$product4.thumbnail) === null || _variant$product4$thu === void 0 ? void 0 : _variant$product4$thu.alt) || ""
      }),
      totalPrice: React.createElement(TaxedMoney, {
        taxedMoney: totalPrice
      }),
      unitPrice: variant === null || variant === void 0 ? void 0 : variant.pricing,
      sku: variant.sku,
      attributes: (_variant$attributes = variant.attributes) === null || _variant$attributes === void 0 ? void 0 : _variant$attributes.map(function (attribute) {
        return {
          attribute: {
            id: attribute.attribute.id,
            name: attribute.attribute.name || ""
          },
          values: attribute.values.map(function (value) {
            return {
              id: value === null || value === void 0 ? void 0 : value.id,
              name: (value === null || value === void 0 ? void 0 : value.name) || "",
              value: value === null || value === void 0 ? void 0 : value.value
            };
          })
        };
      })
    });
  });
};

export var CartPage = function CartPage() {
  var _dataAttention$curren, _dataAttention$curren2, _checkout$shippingMet, _totalPrice$gross, _shippingTaxedPrice$g, _totalPrice$gross2, _totalPrice$net, _shippingTaxedPrice$n, _totalPrice$net2;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showAlert = _React$useState2[0],
      setShowAlert = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      showEdit = _React$useState4[0],
      setShowEdit = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      showAlertAmount = _React$useState6[0],
      setShowAlertAmount = _React$useState6[1];

  var _React$useState7 = React.useState(false),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      showAlertOpening = _React$useState8[0],
      setShowAlertOpening = _React$useState8[1];

  var _React$useState9 = React.useState(false),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      flagLoading = _React$useState10[0],
      setFlagLoading = _React$useState10[1];

  var _React$useState11 = React.useState(""),
      _React$useState12 = _slicedToArray(_React$useState11, 2),
      quantityEdit = _React$useState12[0],
      setQuantityEdit = _React$useState12[1];

  var _React$useState13 = React.useState(""),
      _React$useState14 = _slicedToArray(_React$useState13, 2),
      idEdit = _React$useState14[0],
      setIdEdit = _React$useState14[1];

  var _React$useState15 = React.useState(""),
      _React$useState16 = _slicedToArray(_React$useState15, 2),
      observationEdit = _React$useState16[0],
      setObservationEdit = _React$useState16[1];

  var _React$useState17 = React.useState(""),
      _React$useState18 = _slicedToArray(_React$useState17, 2),
      viewHour = _React$useState18[0],
      setViewHour = _React$useState18[1];

  var navigate = useNavigate();

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout;

  var _useCart = useCart(),
      loaded = _useCart.loaded,
      removeItem = _useCart.removeItem,
      updateItem = _useCart.updateItem,
      items = _useCart.items,
      totalPrice = _useCart.totalPrice,
      subtotalPrice = _useCart.subtotalPrice,
      shippingPrice = _useCart.shippingPrice,
      discount = _useCart.discount;

  var _useCurrentTradeDetai = useCurrentTradeDetails({
    change: false
  }, {
    fetchPolicy: "network-only",
    skip: !(user === null || user === void 0 ? void 0 : user.id)
  }),
      dataAttention = _useCurrentTradeDetai.data;

  var hour = dataAttention === null || dataAttention === void 0 ? void 0 : (_dataAttention$curren = dataAttention.currentTrade) === null || _dataAttention$curren === void 0 ? void 0 : (_dataAttention$curren2 = _dataAttention$curren.nextAttentionTime) === null || _dataAttention$curren2 === void 0 ? void 0 : _dataAttention$curren2.fromHour;
  var shippingTaxedPrice = (checkout === null || checkout === void 0 ? void 0 : (_checkout$shippingMet = checkout.shippingMethod) === null || _checkout$shippingMet === void 0 ? void 0 : _checkout$shippingMet.id) && shippingPrice ? {
    gross: shippingPrice,
    net: shippingPrice
  } : null;
  var promoTaxedPrice = discount && {
    gross: discount,
    net: discount
  };
  var finalTotalPrice = shippingTaxedPrice !== null && totalPrice !== undefined && totalPrice !== null && totalPrice.net !== undefined ? {
    gross: {
      amount: (totalPrice === null || totalPrice === void 0 ? void 0 : (_totalPrice$gross = totalPrice.gross) === null || _totalPrice$gross === void 0 ? void 0 : _totalPrice$gross.amount) - (shippingTaxedPrice === null || shippingTaxedPrice === void 0 ? void 0 : (_shippingTaxedPrice$g = shippingTaxedPrice.gross) === null || _shippingTaxedPrice$g === void 0 ? void 0 : _shippingTaxedPrice$g.amount),
      currency: totalPrice === null || totalPrice === void 0 ? void 0 : (_totalPrice$gross2 = totalPrice.gross) === null || _totalPrice$gross2 === void 0 ? void 0 : _totalPrice$gross2.currency
    },
    net: {
      amount: (totalPrice === null || totalPrice === void 0 ? void 0 : (_totalPrice$net = totalPrice.net) === null || _totalPrice$net === void 0 ? void 0 : _totalPrice$net.amount) - (shippingTaxedPrice === null || shippingTaxedPrice === void 0 ? void 0 : (_shippingTaxedPrice$n = shippingTaxedPrice.net) === null || _shippingTaxedPrice$n === void 0 ? void 0 : _shippingTaxedPrice$n.amount),
      currency: totalPrice === null || totalPrice === void 0 ? void 0 : (_totalPrice$net2 = totalPrice.net) === null || _totalPrice$net2 === void 0 ? void 0 : _totalPrice$net2.currency
    }
  } : totalPrice;

  var refreshHasAttention = function refreshHasAttention() {
    setFlagLoading(true);
    var token = localStorage.getItem("token");
    axios({
      url: apiUrl,
      method: "POST",
      data: {
        query: "\n          query CurrentTradeDetails {\n            me {\n              id\n              currentTrade {\n                id\n                hasAttentionAtCurrentTime\n                nextAttentionTime {\n                  isToday\n                  dayName\n                  fromHour\n                  fromMinute\n                }\n              }\n            }\n          }\n        "
      },
      headers: {
        Authorization: "JWT ".concat(token)
      }
    }).then(function (res) {
      var _res$data, _res$data$data, _res$data$data$me, _res$data2, _res$data2$data, _res$data2$data$me, _res$data2$data$me$cu;

      setFlagLoading(false);

      if (!flagLoading && (res === null || res === void 0 ? void 0 : (_res$data = res.data) === null || _res$data === void 0 ? void 0 : (_res$data$data = _res$data.data) === null || _res$data$data === void 0 ? void 0 : (_res$data$data$me = _res$data$data.me) === null || _res$data$data$me === void 0 ? void 0 : _res$data$data$me.currentTrade) && !(res === null || res === void 0 ? void 0 : (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : (_res$data2$data = _res$data2.data) === null || _res$data2$data === void 0 ? void 0 : (_res$data2$data$me = _res$data2$data.me) === null || _res$data2$data$me === void 0 ? void 0 : (_res$data2$data$me$cu = _res$data2$data$me.currentTrade) === null || _res$data2$data$me$cu === void 0 ? void 0 : _res$data2$data$me$cu.hasAttentionAtCurrentTime) && (user === null || user === void 0 ? void 0 : user.currentTrade) !== null) {
        setShowAlertOpening(true);
        return;
      }

      if (totalPrice !== undefined && totalPrice !== null) {
        var _user$currentTrade;

        if (Number(totalPrice) < Number(user === null || user === void 0 ? void 0 : (_user$currentTrade = user.currentTrade) === null || _user$currentTrade === void 0 ? void 0 : _user$currentTrade.minSpentAmount)) {
          setShowAlert(true);
          return;
        }
      }

      if (quantityAlert !== undefined) {
        var _user$currentTrade2;

        if (quantityAlert < Number(user === null || user === void 0 ? void 0 : (_user$currentTrade2 = user.currentTrade) === null || _user$currentTrade2 === void 0 ? void 0 : _user$currentTrade2.minCheckoutItemsQuantity)) {
          setShowAlertAmount(true);
          return;
        }
      }

      navigate(user ? "/checkout/" : "/login/");
    }) // eslint-disable-next-line no-console
    .catch(function (err) {
      return console.log(err);
    });
  };

  useEffect(function () {
    if (hour && hour > 12) {
      var countHour = 1;

      for (var i = 13; i < 24; i++) {
        if (hour === i) {
          if (hour === 22 || hour === 23) {
            setViewHour("".concat(countHour));
          } else {
            setViewHour("0".concat(countHour));
          }

          break;
        }

        countHour++;
      }
    }

    if (hour && hour >= 10 && hour <= 12) {
      setViewHour("".concat(hour));
    }

    if (hour && hour < 10 || !hour) {
      setViewHour("0".concat(hour));
    }

    return function () {
      setViewHour("0");
    };
  }, [hour]);
  var hourAmPm = "";

  if (hour && hour <= 11) {
    hourAmPm = " am";
  } else {
    hourAmPm = " pm";
  }

  var quantityItemsAlert = items === null || items === void 0 ? void 0 : items.map(function (item) {
    return item.quantity;
  });
  var quantityAlert = quantityItemsAlert === null || quantityItemsAlert === void 0 ? void 0 : quantityItemsAlert.reduce(function (previousValue, currentValue) {
    return currentValue + previousValue;
  }, 0);
  /* Delete shipping price from total price */
  // const overlayContext = React.useContext(OverlayContext);

  var action = function action() {
    var checkoutModify = localStorage.getItem("checkout-modify");

    if (checkoutModify !== null && checkoutModify === "true") {
      navigate("/");
    } else {
      navigate(-1);
    } // setTimeout(() => {
    //   overlayContext.show(OverlayType.cart, OverlayTheme.right);
    // }, 10);

  };

  if (loaded && (items === null || items === void 0 ? void 0 : items.length)) {
    var _dataAttention$curren3, _dataAttention$curren4, _dataAttention$curren5, _dataAttention$curren6, _user$currentTrade3, _user$currentTrade4;

    return React.createElement(React.Fragment, null, React.createElement(Cart, {
      actionGoBack: action,
      title: title,
      button: getCheckoutButton(refreshHasAttention, flagLoading),
      cancelButton: goBackButton(action),
      cartHeader: cartHeader,
      cartFooter: prepareCartFooter(finalTotalPrice, shippingTaxedPrice, promoTaxedPrice, subtotalPrice),
      cart: items && generateCart(items, removeItem, updateItem, setShowEdit, setQuantityEdit, setIdEdit, setObservationEdit, observationEdit),
      quantityItems: quantityItems(items)
    }), showAlertOpening && React.createElement(AlertBusinessOpening, {
      hideModal: function hideModal() {
        setShowAlertOpening(false);
      },
      hour: viewHour,
      minutes: dataAttention === null || dataAttention === void 0 ? void 0 : (_dataAttention$curren3 = dataAttention.currentTrade) === null || _dataAttention$curren3 === void 0 ? void 0 : (_dataAttention$curren4 = _dataAttention$curren3.nextAttentionTime) === null || _dataAttention$curren4 === void 0 ? void 0 : _dataAttention$curren4.fromMinute,
      dayName: dataAttention === null || dataAttention === void 0 ? void 0 : (_dataAttention$curren5 = dataAttention.currentTrade) === null || _dataAttention$curren5 === void 0 ? void 0 : (_dataAttention$curren6 = _dataAttention$curren5.nextAttentionTime) === null || _dataAttention$curren6 === void 0 ? void 0 : _dataAttention$curren6.dayName,
      hourAmPm: hourAmPm
    }), showAlert && React.createElement(AlertCheckoutValue, {
      hideModal: function hideModal() {
        setShowAlert(false);
      },
      minSpent: user === null || user === void 0 ? void 0 : (_user$currentTrade3 = user.currentTrade) === null || _user$currentTrade3 === void 0 ? void 0 : _user$currentTrade3.minSpentAmount,
      currency: totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.net.currency
    }), showAlertAmount && React.createElement(AlertCheckoutAmount, {
      hideModal: function hideModal() {
        setShowAlertAmount(false);
      },
      minItems: user === null || user === void 0 ? void 0 : (_user$currentTrade4 = user.currentTrade) === null || _user$currentTrade4 === void 0 ? void 0 : _user$currentTrade4.minCheckoutItemsQuantity
    }), showEdit && React.createElement(AlertObservation, {
      hideModal: function hideModal() {
        return setShowEdit(false);
      },
      quantity: quantityEdit,
      id: idEdit,
      tempObservation: observationEdit !== "null" && observationEdit !== null && observationEdit !== "" ? observationEdit : "",
      updateItem: updateItem
    }));
  }

  return React.createElement(Navigate, {
    to: "/"
  });
};