import * as React from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { useAuth } from "@saleor/sdk";
import { EasyLink } from "@temp/views/EasyLink";
import { Ecommerce } from "@temp/views/Ecommerce";
import { WebDeliveryman } from "@temp/views/WebDeliveryman";
import { WaLogin, RequireLogin } from "../../views/WaLogin";
import { EpTermsPage } from "../../views/EpTerms";
import AppComponent from "../AppComponent";
import AppComponentDelivery from "../AppComponentDelivery";
import * as paths from "./paths";
import * as pathsDelivery from "./pathsDelivery";

var RouteRequiresLogin = function RouteRequiresLogin(_ref) {
  var _user$role, _user$role2;

  var mainTradeSlug = _ref.mainTradeSlug;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  return (user === null || user === void 0 ? void 0 : (_user$role = user.role) === null || _user$role === void 0 ? void 0 : _user$role.toUpperCase()) === "CUSTOMER" || location.pathname.includes(paths.neighborhoodsUrl) ? React.createElement(Outlet, null) : (user === null || user === void 0 ? void 0 : (_user$role2 = user.role) === null || _user$role2 === void 0 ? void 0 : _user$role2.toUpperCase()) === "DELIVERY_MAN" ? React.createElement(AppComponentDelivery, null) : mainTradeSlug && mainTradeSlug !== "" ? React.createElement(Navigate, {
    to: "/web/".concat(mainTradeSlug),
    replace: true
  }) : React.createElement(RequireLogin, null);
};

export var InitRoutes = function InitRoutes(_ref2) {
  var mainTradeSlug = _ref2.mainTradeSlug;
  return React.createElement(Routes, null, React.createElement(Route, {
    path: paths.easyLinkUrl,
    element: React.createElement(EasyLink, null)
  }), React.createElement(Route, {
    path: paths.ecommerceUrl,
    element: React.createElement(Ecommerce, null)
  }), React.createElement(Route, {
    path: paths.deliveryUrl,
    element: React.createElement(WebDeliveryman, null)
  }), React.createElement(Route, {
    path: paths.waLogin,
    element: React.createElement(WaLogin, null)
  }), React.createElement(Route, {
    path: paths.waLoginProfile,
    element: React.createElement(WaLogin, null)
  }), React.createElement(Route, {
    path: paths.waLoginOrders,
    element: React.createElement(WaLogin, null)
  }), React.createElement(Route, {
    path: paths.waLoginOrdersInProgress,
    element: React.createElement(WaLogin, null)
  }), React.createElement(Route, {
    path: paths.waLoginOrder,
    element: React.createElement(WaLogin, null)
  }), React.createElement(Route, {
    path: pathsDelivery.waDeliveryLogin,
    element: React.createElement(WaLogin, null)
  }), React.createElement(Route, {
    path: pathsDelivery.waDeliveryLoginOrder,
    element: React.createElement(WaLogin, null)
  }), React.createElement(Route, {
    path: paths.epTermsPageUrl,
    element: React.createElement(EpTermsPage, null)
  }), React.createElement(Route, {
    path: "*",
    element: React.createElement(RouteRequiresLogin, {
      mainTradeSlug: mainTradeSlug
    })
  }, React.createElement(Route, {
    path: "*",
    element: React.createElement(AppComponent, null)
  })));
};
export default InitRoutes;