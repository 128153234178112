import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { FormattedMessage, useIntl } from "react-intl";
import { animated, useSpring } from "react-spring";
import { Button, Loader } from "@components/atoms";
import { getErrorMessage, neighborhoodMessages } from "@temp/intl";

var closeWhite = require("../../images/closewhite.svg");

export var AlertNeighborhoodDecision = function AlertNeighborhoodDecision(_ref) {
  var errors = _ref.errors,
      neighborhood = _ref.neighborhood,
      city = _ref.city,
      loading = _ref.loading,
      hideModal = _ref.hideModal,
      submit = _ref.submit;
  var intl = useIntl();
  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "alertNeighborhoodDecision",
    onClick: hideModal
  }), React.createElement("div", {
    className: "alertNeighborhoodDecision__data"
  }, React.createElement("div", {
    className: "alertNeighborhoodDecision__data__close"
  }, React.createElement("p", null, intl.formatMessage(neighborhoodMessages.confirmNeighborhoodTitle)), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "alertNeighborhoodDecision__data__content alertNeighborhoodDecision__data__content--first"
  }), React.createElement("div", {
    className: "alertNeighborhoodDecision__data__content  alertNeighborhoodDecision__data__content--amount alertNeighborhoodDecision__data__content--first alertNeighborhoodDecision__data__content--last"
  }, React.createElement("p", {
    key: "neighborhoodQuestion"
  }, intl.formatMessage(neighborhoodMessages.confirmNeighborhoodQuestion, {
    neighborhoodName: React.createElement("span", {
      key: "neighborhood-name"
    }, "".concat(neighborhood, ",")),
    cityName: React.createElement("span", {
      key: "city-name"
    }, city)
  })), errors.length > 0 && React.createElement("div", {
    className: "validateCode__error"
  }, errors.map(function (error, index) {
    return React.createElement("p", {
      key: "".concat(index, "-errorNeighborhoodChange")
    }, getErrorMessage(error.code, intl));
  }))), React.createElement("div", {
    className: "alertNeighborhoodDecision__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, React.createElement(FormattedMessage, {
    id: "components.AlertNeighborhoodDecision.index.2680335018",
    defaultMessage: "No"
  })), React.createElement(animated.button, {
    className: "alertNeighborhoodDecision__data__footer__p",
    type: "button",
    onClick: submit,
    style: scaleButton
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.AlertNeighborhoodDecision.index.3308036799",
    defaultMessage: "Yes"
  }))))), loading && React.createElement("div", {
    className: "alertNeighborhoodDecision__loading"
  }, React.createElement(Loader, null)));
};
export default AlertNeighborhoodDecision;