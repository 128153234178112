import * as React from "react";
import { useAuth, useShopDetails } from "@saleor/sdk";
import { maybe } from "../../core/utils";
import { defaultContext, ShopContext } from "./context";

var ShopProvider = function ShopProvider(_ref) {
  var children = _ref.children;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _useShopDetails = useShopDetails({
    skip: !(user === null || user === void 0 ? void 0 : user.id)
  }),
      data = _useShopDetails.data;

  return React.createElement(ShopContext.Provider, {
    value: maybe(function () {
      return data.shop;
    }, defaultContext)
  }, children);
};

export default ShopProvider;