import "./scss/styles.scss";
import React from "react";
import PhoneInput from "react-phone-number-input";
import { useIntl } from "react-intl";
import { getErrorMessage, logInMessages } from "@temp/intl";
export var LoginInput = function LoginInput(_ref) {
  var showValidateCode = _ref.showValidateCode,
      defaultCountryCode = _ref.defaultCountryCode,
      userWaNumber = _ref.userWaNumber,
      setUserWaNumber = _ref.setUserWaNumber,
      errorsVerifyAccount = _ref.errorsVerifyAccount;
  var intl = useIntl();
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "loginInput",
    style: {
      backgroundColor: showValidateCode ? "#D5D0D0" : "transparent"
    }
  }, React.createElement(PhoneInput, {
    readOnly: showValidateCode,
    autoFocus: true,
    defaultCountry: defaultCountryCode,
    placeholder: intl.formatMessage(logInMessages.waNumber),
    value: userWaNumber,
    onChange: setUserWaNumber
  })), errorsVerifyAccount.length > 0 && React.createElement("div", {
    className: "error"
  }, errorsVerifyAccount.map(function (error, index) {
    return React.createElement("p", {
      key: "".concat(index, "-errorVerifyCustomer")
    }, getErrorMessage(error.code, intl, {
      minutesToUnlock: (error === null || error === void 0 ? void 0 : error.secondsToUnlock) ? (error === null || error === void 0 ? void 0 : error.secondsToUnlock) / 60 + 1 : 1
    }));
  })));
};
LoginInput.displayName = "LoginInput";