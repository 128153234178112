import "./scss/index.scss";
import React from "react";
import ReactSVG from "react-svg";
import { useIntl } from "react-intl";
import { animated, useSpring } from "react-spring";
import { neighborhoodMessages } from "@temp/intl";

var closeWhite = require("../../images/closewhite.svg");

export var AlertNeighborhoodWithOutCoverage = function AlertNeighborhoodWithOutCoverage(_ref) {
  var neighborhood = _ref.neighborhood,
      hideModal = _ref.hideModal;
  var intl = useIntl();
  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "alertNeighborhoodWithOutCoverage",
    onClick: hideModal
  }), React.createElement("div", {
    className: "alertNeighborhoodWithOutCoverage__data"
  }, React.createElement("div", {
    className: "alertNeighborhoodWithOutCoverage__data__close"
  }, React.createElement("p", null, intl.formatMessage(neighborhoodMessages.withOutCoverage)), React.createElement(ReactSVG, {
    path: closeWhite,
    onClick: hideModal
  })), React.createElement("div", {
    className: "alertNeighborhoodWithOutCoverage__data__content alertNeighborhoodWithOutCoverage__data__content--first"
  }), React.createElement("div", {
    className: "alertNeighborhoodWithOutCoverage__data__content  alertNeighborhoodWithOutCoverage__data__content--amount alertNeighborhoodWithOutCoverage__data__content--first alertNeighborhoodWithOutCoverage__data__content--last"
  }, React.createElement("p", {
    key: "neighborhoodQuestion"
  }, intl.formatMessage(neighborhoodMessages.neighborhoodWithOutCoverage, {
    neighborhoodName: React.createElement("span", {
      key: "neighborhood-name-withoutcoverage"
    }, "".concat(neighborhood))
  }))), React.createElement("div", {
    className: "alertNeighborhoodWithOutCoverage__data__footer"
  }, React.createElement(animated.button, {
    className: "alertNeighborhoodWithOutCoverage__data__footer__p",
    type: "button",
    onClick: hideModal,
    style: scaleButton
  }, React.createElement("p", null, intl.formatMessage(neighborhoodMessages.changeNeighborhood))))));
};
export default AlertNeighborhoodWithOutCoverage;