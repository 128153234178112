import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from "react";
import ReactSVG from "react-svg";
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useAuth, useOrdersInProgressAmountByUser } from "@saleor/sdk";
import "./scss/index.scss";
import { removeScrollItems } from "@temp/core/utils";
import * as paths from "../../app/routes/paths";
import AlertLogin from "../AlertLogin";

var shopImage = require("../../images/nav/shop.svg");

var hallWaysImage = require("../../images/nav/aisles.svg");

var offersImage = require("../../images/nav/offers.svg");

var myOrdersImage = require("../../images/nav/myOrdersImg.svg");

var restaurantImage = require("../../images/nav/restaurant.svg");

var NavItem = function NavItem(props) {
  var className = props.className || "container__link";
  var color = props.linkTo === window.location.pathname ? "yellow" : "white";

  var _useOrdersInProgressA = useOrdersInProgressAmountByUser({
    fetchPolicy: "network-only"
  }),
      dataOrderInProgressAmount = _useOrdersInProgressA.data,
      loadingOrderInProgressAmount = _useOrdersInProgressA.loading;

  return React.createElement(NavLink, {
    to: props.linkTo,
    className: className,
    onClick: function onClick(e) {
      removeScrollItems();

      if (props.handleOnClick) {
        props.handleOnClick(e);
      }
    }
  }, React.createElement(ReactSVG, {
    path: props.svgPath,
    svgStyle: {
      fill: color
    }
  }), React.createElement("span", null, props.label), dataOrderInProgressAmount !== null && dataOrderInProgressAmount.ordersInProgressAmount > 0 && !loadingOrderInProgressAmount && props.linkTo === paths.orderHistoryUrl && React.createElement("div", null, React.createElement("p", {
    className: "footer__orderInProgress"
  }, dataOrderInProgressAmount.ordersInProgressAmount)));
};

var Nav = function Nav(props) {
  var _useAuth = useAuth(),
      user = _useAuth.user;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showAlertLogin = _React$useState2[0],
      setShowAlertLogin = _React$useState2[1];

  var restaurant = function restaurant(user) {
    var _user$currentTrade;

    var indexFinalData = (_user$currentTrade = user.currentTrade) === null || _user$currentTrade === void 0 ? void 0 : _user$currentTrade.tradeTypes.findIndex(function (data) {
      return data.uniqueType.includes(5);
    });

    if (indexFinalData !== -1) {
      return true;
    }

    return false;
  };

  var restaurantValue = restaurant(user);
  var handleOnClickSaleLink = React.useCallback(function () {
    localStorage.setItem("sale_products_link", "true");
    localStorage.setItem("sale_search_clean", " true");
  }, []);
  var handleOnClickHomeLink = React.useCallback(function () {
    localStorage.setItem("home_search_clean", " true");
  }, []);
  return user.currentTrade ? React.createElement(React.Fragment, null, React.createElement("div", {
    className: "footer",
    id: "footer"
  }, React.createElement("footer", {
    className: "footer-nav"
  }, React.createElement("div", {
    className: "container"
  }, restaurantValue ? React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.Footer.Nav.1119152923",
      defaultMessage: "Menu"
    }),
    linkTo: paths.baseUrl,
    svgPath: restaurantImage,
    handleOnClick: function handleOnClick() {
      return handleOnClickHomeLink();
    }
  }) : React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.Footer.Nav.3069060397",
      defaultMessage: "Shop"
    }),
    linkTo: paths.baseUrl,
    svgPath: shopImage,
    handleOnClick: function handleOnClick() {
      return handleOnClickHomeLink();
    }
  }), restaurantValue && !props.withOutCategories ? React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.Footer.Nav.3583204912",
      defaultMessage: "Categories"
    }),
    linkTo: paths.categoriesUrl,
    svgPath: hallWaysImage
  }) : !props.withOutCategories && React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.Footer.Nav.3583204912",
      defaultMessage: "Categories"
    }),
    linkTo: paths.categoriesUrl,
    svgPath: hallWaysImage
  }), React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.Footer.Nav.468441986",
      defaultMessage: "Sale"
    }),
    linkTo: paths.saleUrl,
    svgPath: offersImage,
    handleOnClick: function handleOnClick() {
      return handleOnClickSaleLink();
    }
  }), React.createElement(NavItem, {
    label: React.createElement(FormattedMessage, {
      id: "components.Footer.Nav.2364847682",
      defaultMessage: "Orders"
    }),
    linkTo: paths.orderHistoryUrl,
    svgPath: myOrdersImage,
    handleOnClick: function handleOnClick(e) {
      if (user === null || user === void 0 ? void 0 : user.isAnonymousUser) {
        e.preventDefault();
        setShowAlertLogin(true);
      }
    }
  })))), showAlertLogin && React.createElement(AlertLogin, {
    hideModal: function hideModal() {
      return setShowAlertLogin(false);
    }
  })) : React.createElement(React.Fragment, null);
};

export default Nav;