import React from "react";
import { animated, useSpring } from "react-spring";
import { LoginInput, LoginValidateCode } from "./components";
export var LoginManager = function LoginManager(_ref) {
  var buttonAccessLabel = _ref.buttonAccessLabel,
      accessCodeSeconds = _ref.accessCodeSeconds,
      disabledAccessButton = _ref.disabledAccessButton,
      classNameButton = _ref.classNameButton,
      code = _ref.code,
      defaultCountryCode = _ref.defaultCountryCode,
      errorsValidateCode = _ref.errorsValidateCode,
      errorsVerifyAccount = _ref.errorsVerifyAccount,
      resendCode = _ref.resendCode,
      seeCode = _ref.seeCode,
      showValidateCode = _ref.showValidateCode,
      siteSettings = _ref.siteSettings,
      userHasAcceptedTyc = _ref.userHasAcceptedTyc,
      userWaNumber = _ref.userWaNumber,
      handleChange = _ref.handleChange,
      handleCodeInput = _ref.handleCodeInput,
      handleLogIn = _ref.handleLogIn,
      handleReSendCode = _ref.handleReSendCode,
      handleSeeCode = _ref.handleSeeCode,
      handleValidateCode = _ref.handleValidateCode,
      setUserWaNumber = _ref.setUserWaNumber;
  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  return React.createElement(React.Fragment, null, React.createElement(LoginInput, {
    showValidateCode: showValidateCode,
    defaultCountryCode: defaultCountryCode,
    userWaNumber: userWaNumber,
    setUserWaNumber: setUserWaNumber,
    errorsVerifyAccount: errorsVerifyAccount
  }), showValidateCode && React.createElement(LoginValidateCode, {
    accessCodeSeconds: accessCodeSeconds,
    seeCode: seeCode,
    errorsValidateCode: errorsValidateCode,
    code: code,
    resendCode: resendCode,
    siteSettings: siteSettings,
    userHasAcceptedTyc: userHasAcceptedTyc,
    userWaNumber: userWaNumber,
    handleChange: handleChange,
    handleCodeInput: handleCodeInput,
    handleSeeCode: handleSeeCode,
    handleValidateCode: handleValidateCode,
    handleReSendCode: handleReSendCode
  }), !showValidateCode && React.createElement(React.Fragment, null, !disabledAccessButton && React.createElement(animated.input, {
    style: scaleButton,
    type: "submit",
    className: classNameButton,
    onClick: function onClick(e) {
      return handleLogIn(e, userWaNumber);
    },
    value: buttonAccessLabel
  })));
};
export default LoginManager;