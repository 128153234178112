import "./scss/index.scss";
import React from "react";
import { Button } from "@components/atoms";
export var AlertAnonymousChoice = function AlertAnonymousChoice(_ref) {
  var hideModal = _ref.hideModal,
      submit = _ref.submit,
      tradeName = _ref.tradeName,
      tradeLogo = _ref.tradeLogo;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "alertAnonymousChoice"
  }), React.createElement("div", {
    className: "alertAnonymousChoice__data"
  }, React.createElement("div", {
    className: "alertAnonymousChoice__data__content alertAnonymousChoice__data__content--first"
  }), React.createElement("div", {
    className: "alertAnonymousChoice__data__content  alertAnonymousChoice__data__content--amount alertAnonymousChoice__data__content--first alertAnonymousChoice__data__content--last"
  }, React.createElement("div", {
    className: "alertAnonymousChoice__data__content__logo"
  }, React.createElement("img", {
    alt: tradeLogo === null || tradeLogo === void 0 ? void 0 : tradeLogo.alt,
    src: tradeLogo === null || tradeLogo === void 0 ? void 0 : tradeLogo.url
  })), React.createElement("p", null, tradeName), React.createElement("p", null, "Identif\xEDcate con tu cuenta de usuario \xF3 ingresa sin cuenta y selecciona tu barrio para ver los productos")), React.createElement("div", {
    className: "alertAnonymousChoice__data__footer"
  }, React.createElement(Button, {
    type: "button",
    testingContext: "",
    onClick: hideModal
  }, "Identificarme"), React.createElement(Button, {
    className: "alertAnonymousChoice__data__footer__p",
    testingContext: "",
    type: "button",
    onClick: submit
  }, "Ingresar sin cuenta"))));
};
export default AlertAnonymousChoice;