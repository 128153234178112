import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useMemo } from "react";
import "../globalStyles/scss/index.scss";
import { InitRoutes } from "./routes/InitRoutes";
import * as paths from "./routes/paths";
import * as pathsDelivery from "./routes/pathsDelivery";

var App = function App() {
  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      updateMainTradeSlug = _React$useState2[0],
      setUpdateMainTradeSlug = _React$useState2[1];

  var mainTradeSlug = useMemo(function () {
    return localStorage.getItem("mainTrade") ? JSON.parse(localStorage.getItem("mainTrade")).mainTradeData.slug : "";
  }, [updateMainTradeSlug]);
  useEffect(function () {
    var _location, _location2, _location3, _location4, _location5, _location6, _location7, _location8;

    var timeOut;

    if (paths.waLoginRegExp.test((_location = location) === null || _location === void 0 ? void 0 : _location.pathname) || paths.waLoginOrderRegExp.test((_location2 = location) === null || _location2 === void 0 ? void 0 : _location2.pathname) || paths.waLoginProfileRegExp.test((_location3 = location) === null || _location3 === void 0 ? void 0 : _location3.pathname) || paths.waLoginOrdersRegExp.test((_location4 = location) === null || _location4 === void 0 ? void 0 : _location4.pathname) || paths.waLoginOrdersInProgressRegExp.test((_location5 = location) === null || _location5 === void 0 ? void 0 : _location5.pathname) || pathsDelivery.waDeliveryLoginRegExp.test((_location6 = location) === null || _location6 === void 0 ? void 0 : _location6.pathname) || pathsDelivery.waDeliveryLoginOrderRegExp.test((_location7 = location) === null || _location7 === void 0 ? void 0 : _location7.pathname) || paths.ecommerceRegExp.test((_location8 = location) === null || _location8 === void 0 ? void 0 : _location8.pathname)) {
      timeOut = setTimeout(function () {
        setUpdateMainTradeSlug(function (mainTrade) {
          return !mainTrade;
        });
      }, 2000);
    }

    if (window.location.pathname.includes("deliveryman")) {
      document.title = "EasyPedido - Repartidor";
    }

    return function () {
      clearTimeout(timeOut);
    };
  }, [location.pathname]);
  return React.createElement(InitRoutes, {
    mainTradeSlug: mainTradeSlug
  });
};

export default App;