import { useAuth } from "@saleor/sdk";
import { GOOGLE_RECAPTCHA_KEY } from "@temp/constants";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Neighborhood } from "./Neighborhood";
export var NeighborhoodPage = function NeighborhoodPage() {
  var _useAuth = useAuth(),
      user = _useAuth.user;

  if (user) {
    return React.createElement(Neighborhood, null);
  }

  return React.createElement(GoogleReCaptchaProvider, {
    reCaptchaKey: GOOGLE_RECAPTCHA_KEY
  }, React.createElement(Neighborhood, null));
};