import { AccountErrorCode, UbicationErrorCode } from "@saleor/sdk";
import { defineMessages } from "react-intl";
export var commonMessages = defineMessages({
  acceptPtd: {
    "id": "intl.commonMessages.acceptPtd",
    defaultMessage: "To continue, you must accept the privacy policy"
  },
  acceptTyc: {
    "id": "intl.commonMessages.acceptTyc",
    defaultMessage: "To continue, you must accept the terms and conditions"
  },
  acceptTycAndPtd: {
    "id": "intl.commonMessages.acceptTycAndPtd",
    defaultMessage: "To continue, you must accept the terms and conditions, and the privacy policy"
  },
  all: {
    "id": "intl.commonMessages.all",
    defaultMessage: "All"
  },
  apply: {
    "id": "intl.commonMessages.apply",
    defaultMessage: "Apply"
  },
  back: {
    "id": "intl.commonMessages.back",
    defaultMessage: "Back"
  },
  ID: {
    "id": "intl.commonMessages.ID",
    defaultMessage: "ID"
  },
  IDType: {
    "id": "intl.commonMessages.IDType",
    defaultMessage: "ID Type"
  },
  card: {
    "id": "intl.commonMessages.card",
    defaultMessage: "Card"
  },
  cash: {
    "id": "intl.commonMessages.cash",
    defaultMessage: "Cash"
  },
  category: {
    "id": "intl.commonMessages.category",
    defaultMessage: "Category"
  },
  change: {
    "id": "intl.commonMessages.change",
    defaultMessage: "Change"
  },
  choose: {
    "id": "intl.commonMessages.choose",
    defaultMessage: "Choose"
  },
  clean: {
    "id": "intl.commonMessages.clean",
    defaultMessage: "Clean"
  },
  continue: {
    "id": "intl.commonMessages.continue",
    defaultMessage: "Continue"
  },
  credit: {
    "id": "intl.commonMessages.credit",
    defaultMessage: "Credit"
  },
  debit: {
    "id": "intl.commonMessages.debit",
    defaultMessage: "Debit"
  },
  editAddress: {
    "id": "intl.commonMessages.editAddress",
    defaultMessage: "Edit Address"
  },
  fieldRequired: {
    "id": "intl.commonMessages.fieldRequired",
    defaultMessage: "This field is required"
  },
  filter: {
    "id": "intl.commonMessages.filter",
    defaultMessage: "Filter"
  },
  isNotFound: {
    "id": "intl.commonMessages.isNotFound",
    defaultMessage: "Is not found"
  },
  search: {
    "id": "intl.commonMessages.search",
    defaultMessage: "search"
  },
  outOfStock: {
    "id": "intl.commonMessages.outOfStock",
    defaultMessage: "Out of stock"
  },
  obligatory: {
    "id": "intl.commonMessages.obligatory",
    defaultMessage: "Obligatory."
  },
  lowStock: {
    "id": "intl.commonMessages.lowStock",
    defaultMessage: "Low stock"
  },
  nequi: {
    "id": "intl.commonMessages.nequi",
    defaultMessage: "Nequi"
  },
  noItemsAvailable: {
    "id": "intl.commonMessages.noItemsAvailable",
    defaultMessage: "No items available"
  },
  noPurchaseAvailable: {
    "id": "intl.commonMessages.noPurchaseAvailable",
    defaultMessage: "Not available for purchase"
  },
  purchaseAvailableOn: {
    "id": "intl.commonMessages.purchaseAvailableOn",
    defaultMessage: "Will become available for purchase on {date} at {time}"
  },
  youMightLike: {
    "id": "intl.commonMessages.youMightLike",
    defaultMessage: "You might like"
  },
  choosePaymentMethod: {
    "id": "intl.commonMessages.choosePaymentMethod",
    defaultMessage: "Please choose payment method."
  },
  provideEmailAddress: {
    "id": "intl.commonMessages.provideEmailAddress",
    defaultMessage: "Please provide email address."
  },
  account: {
    "id": "intl.commonMessages.account",
    defaultMessage: "Account"
  },
  myAccount: {
    "id": "intl.commonMessages.myAccount",
    defaultMessage: "My Account"
  },
  orderHistory: {
    "id": "intl.commonMessages.orderHistory",
    defaultMessage: "Orders"
  },
  openWith: {
    "id": "intl.commonMessages.openWith",
    defaultMessage: "Open with"
  },
  addressBook: {
    "id": "intl.commonMessages.addressBook",
    defaultMessage: "My addresses"
  },
  assignedOrders: {
    "id": "intl.commonMessages.assignedOrders",
    defaultMessage: "Assigned orders"
  },
  logIn: {
    "id": "intl.commonMessages.logIn",
    defaultMessage: "Log In"
  },
  logOut: {
    "id": "intl.commonMessages.logOut",
    defaultMessage: "Log Out"
  },
  firstName: {
    "id": "intl.commonMessages.firstName",
    defaultMessage: "First Name"
  },
  lastName: {
    "id": "intl.commonMessages.lastName",
    defaultMessage: "Last Name"
  },
  password: {
    "id": "intl.commonMessages.password",
    defaultMessage: "Password"
  },
  profile: {
    "id": "intl.commonMessages.profile",
    defaultMessage: "Profile"
  },
  quantity: {
    "id": "intl.commonMessages.quantity",
    defaultMessage: "Quantity"
  },
  sku: {
    "id": "intl.commonMessages.sku",
    defaultMessage: "SKU"
  },
  subCategory: {
    "id": "intl.commonMessages.subCategory",
    defaultMessage: "Subcategory"
  },
  maxQtyIs: {
    "id": "intl.commonMessages.maxQtyIs",
    defaultMessage: "Maximum quantity is {maxQuantity}"
  },
  qty: {
    "id": "intl.commonMessages.qty",
    defaultMessage: "Quantity"
  },
  subtotal: {
    "id": "intl.commonMessages.subtotal",
    defaultMessage: "SUBTOTAL"
  },
  shipping: {
    "id": "intl.commonMessages.shipping",
    defaultMessage: "SHIPPING"
  },
  promoCode: {
    "id": "intl.commonMessages.promoCode",
    defaultMessage: "discount"
  },
  total: {
    "id": "intl.commonMessages.total",
    defaultMessage: "TOTAL"
  },
  totalPrice: {
    "id": "intl.commonMessages.totalPrice",
    defaultMessage: "TOTAL PRICE"
  },
  checkout: {
    "id": "intl.commonMessages.checkout",
    defaultMessage: "Checkout"
  },
  eMail: {
    "id": "intl.commonMessages.eMail",
    defaultMessage: "Email Address"
  },
  shortEmail: {
    "id": "intl.commonMessages.shortEmail",
    defaultMessage: "Email"
  },
  loading: {
    "id": "intl.commonMessages.loading",
    defaultMessage: "Loading"
  },
  products: {
    "id": "intl.commonMessages.products",
    defaultMessage: "Products"
  },
  price: {
    "id": "intl.commonMessages.price",
    defaultMessage: "Price"
  },
  variant: {
    "id": "intl.commonMessages.variant",
    defaultMessage: "Variant"
  },
  phone: {
    "id": "intl.commonMessages.phone",
    defaultMessage: "Phone"
  },
  phoneNumber: {
    "id": "intl.commonMessages.phoneNumber",
    defaultMessage: "Phone number: {phone}"
  },
  showEmail: {
    "id": "intl.commonMessages.showEmail",
    defaultMessage: "Email: {email}"
  },
  save: {
    "id": "intl.commonMessages.save",
    defaultMessage: "Save"
  },
  add: {
    "id": "intl.commonMessages.add",
    defaultMessage: "Add"
  },
  filterHeader: {
    "id": "intl.commonMessages.filterHeader",
    defaultMessage: "FILTERS"
  },
  clearFilterHeader: {
    "id": "intl.commonMessages.clearFilterHeader",
    defaultMessage: "CLEAR FILTERS"
  },
  status: {
    "id": "intl.commonMessages.status",
    defaultMessage: "Status"
  },
  cancel: {
    "id": "intl.commonMessages.cancel",
    defaultMessage: "Cancel"
  },
  home: {
    "id": "intl.commonMessages.home",
    defaultMessage: "Home"
  },
  notDefined: {
    "id": "intl.commonMessages.notDefined",
    defaultMessage: "Not defined"
  },
  ptd: {
    "id": "intl.commonMessages.ptd",
    defaultMessage: "privacy policy"
  },
  sale: {
    "id": "intl.commonMessages.sale",
    defaultMessage: "Sale"
  },
  seeMore: {
    "id": "intl.commonMessages.seeMore",
    defaultMessage: "See more"
  },
  seeLess: {
    "id": "intl.commonMessages.seeLess",
    defaultMessage: "See less"
  },
  select: {
    "id": "intl.commonMessages.select",
    defaultMessage: "Select"
  },
  toAccess: {
    "id": "intl.commonMessages.toAccess",
    defaultMessage: "To access"
  },
  tyc: {
    "id": "intl.commonMessages.tyc",
    defaultMessage: "Terms and conditions"
  },
  voucher: {
    "id": "intl.commonMessages.voucher",
    defaultMessage: "Voucher"
  },
  weDidNotFindProductsInTheCategory: {
    "id": "intl.commonMessages.weDidNotFindProductsInTheCategory",
    defaultMessage: "We did not find products in the category"
  },
  weDidNotFindProductsWithTheWord: {
    "id": "intl.commonMessages.weDidNotFindProductsWithTheWord",
    defaultMessage: "We did not find products with the word"
  },
  weDidNotFindProductsInTheCategorySale: {
    "id": "intl.commonMessages.weDidNotFindProductsInTheCategorySale",
    defaultMessage: "We did not find discounts in the category"
  },
  weDidNotFindProductsWithTheWordSale: {
    "id": "intl.commonMessages.weDidNotFindProductsWithTheWordSale",
    defaultMessage: "We did not find discounts with the word"
  },
  inTheCategory: {
    "id": "intl.commonMessages.inTheCategory",
    defaultMessage: "in the category"
  },
  checkThatTheWordIsSpelledCorrectly: {
    "id": "intl.commonMessages.checkThatTheWordIsSpelledCorrectly",
    defaultMessage: "Check that the word is spelled correctly. You can too try modifying the category and subcategory filters"
  },
  checkThatTheWordIsSpelledCorrectlySale: {
    "id": "intl.commonMessages.checkThatTheWordIsSpelledCorrectlySale",
    defaultMessage: "Check that the word is spelled correctly. You can too try modifying the category filter"
  },
  update: {
    "id": "intl.commonMessages.update",
    defaultMessage: "Update"
  },
  youCanTryModifyingFilters: {
    "id": "intl.commonMessages.youCanTryModifyingFilters",
    defaultMessage: "You can try modifying the category and subcategory filters"
  },
  youCanTryModifyingFiltersSale: {
    "id": "intl.commonMessages.youCanTryModifyingFiltersSale",
    defaultMessage: "You can try modifying the category filter"
  }
});
export var checkoutMessages = defineMessages({
  completeShippingAddress: {
    "id": "intl.checkoutMessages.completeShippingAddress",
    defaultMessage: "Complete shipping address"
  },
  stepNameAddress: {
    "id": "intl.checkoutMessages.stepNameAddress",
    defaultMessage: "Address"
  },
  stepNameShipping: {
    "id": "intl.checkoutMessages.stepNameShipping",
    defaultMessage: "Shipping"
  },
  stepNamePayment: {
    "id": "intl.checkoutMessages.stepNamePayment",
    defaultMessage: "Payment"
  },
  stepNameReview: {
    "id": "intl.checkoutMessages.stepNameReview",
    defaultMessage: "Review"
  },
  addressNextActionName: {
    "id": "intl.checkoutMessages.addressNextActionName",
    defaultMessage: "Continue to Shipping"
  },
  firstName: {
    "id": "intl.checkoutMessages.firstName",
    defaultMessage: "name address"
  },
  defineFirstName: {
    "id": "intl.checkoutMessages.defineFirstName",
    defaultMessage: "define name address"
  },
  shippingNextActionName: {
    "id": "intl.checkoutMessages.shippingNextActionName",
    defaultMessage: "Continue to Payment"
  },
  paymentNextActionName: {
    "id": "intl.checkoutMessages.paymentNextActionName",
    defaultMessage: "Continue to Review"
  },
  reviewNextActionName: {
    "id": "intl.checkoutMessages.reviewNextActionName",
    defaultMessage: "Place order"
  },
  addNewAddress: {
    "id": "intl.checkoutMessages.addNewAddress",
    defaultMessage: "Add new address"
  },
  shippingCost: {
    "id": "intl.checkoutMessages.shippingCost",
    defaultMessage: "US"
  },
  shippingFree: {
    "id": "intl.checkoutMessages.shippingFree",
    defaultMessage: "Free"
  },
  shippingLabelCost: {
    "id": "intl.checkoutMessages.shippingLabelCost",
    defaultMessage: "The establishment has a shipping cost of"
  },
  shippingLabelFree: {
    "id": "intl.checkoutMessages.shippingLabelFree",
    defaultMessage: "The delivery of your order is"
  },
  shippingMethod: {
    "id": "intl.checkoutMessages.shippingMethod",
    defaultMessage: "Cost of shipping"
  },
  shippingMessage: {
    "id": "intl.checkoutMessages.shippingMessage",
    defaultMessage: "The establishment uses its own delivery drivers"
  },
  billingAddress: {
    "id": "intl.checkoutMessages.billingAddress",
    defaultMessage: "BILLING ADDRESS"
  },
  myHome: {
    "id": "intl.checkoutMessages.myHome",
    defaultMessage: "My Home"
  },
  myJob: {
    "id": "intl.checkoutMessages.myJob",
    defaultMessage: "My Office"
  },
  myBoyfriendHouse: {
    "id": "intl.checkoutMessages.myBoyfriendHouse",
    defaultMessage: "My Partner"
  },
  other: {
    "id": "intl.checkoutMessages.other",
    defaultMessage: "Other"
  },
  paymentMethod: {
    "id": "intl.checkoutMessages.paymentMethod",
    defaultMessage: "SELECT PAYMENT METHOD"
  },
  reviewOrder: {
    "id": "intl.checkoutMessages.reviewOrder",
    defaultMessage: "Order summary"
  },
  selectShippingAddress: {
    "id": "intl.checkoutMessages.selectShippingAddress",
    defaultMessage: "Select shipping address"
  },
  shippingAddress: {
    "id": "intl.checkoutMessages.shippingAddress",
    defaultMessage: "Address"
  },
  shippingAddressSummary: {
    "id": "intl.checkoutMessages.shippingAddressSummary",
    defaultMessage: "Shipping Address"
  },
  continueShopping: {
    "id": "intl.checkoutMessages.continueShopping",
    defaultMessage: "CONTINUE SHOPPING"
  },
  neighborhood: {
    "id": "intl.checkoutMessages.neighborhood",
    defaultMessage: "Neighborhood"
  },
  priceShipping: {
    "id": "intl.checkoutMessages.priceShipping",
    defaultMessage: "Price shipping"
  }
});
export var errorMessage = defineMessages({
  codeRequired: {
    "id": "intl.errorMessage.codeRequired",
    defaultMessage: "Code is required"
  },
  customerNotFound: {
    "id": "intl.errorMessage.customerNotFound",
    defaultMessage: "User does not exist"
  },
  customerNotActive: {
    "id": "intl.errorMessage.customerNotActive",
    defaultMessage: "User is not active"
  },
  exceededMaximumNumberOfAttempts: {
    "id": "intl.errorMessage.exceededMaximumNumberOfAttempts",
    defaultMessage: "You have exceeded the maximum number of attempts to generate a new access code. Try again in {minute} minutes"
  },
  geolocationError: {
    "id": "intl.errorMessage.geolocationError",
    defaultMessage: "The browser does not support geolocation"
  },
  notAccessTermsAndConditions: {
    "id": "intl.errorMessage.notAccessTermsAndConditions",
    defaultMessage: "You must accept the terms and conditions"
  },
  neighborhoodDoesNotHaveCoverage: {
    "id": "intl.errorMessage.neighborhoodDoesNotHaveCoverage",
    defaultMessage: "Neighborhood does not have coverage"
  },
  neighborhoodNotFound: {
    "id": "intl.errorMessage.neighborhoodNotFound",
    defaultMessage: "Neighborhood not found"
  },
  permissionDeniedUbication: {
    "id": "intl.errorMessage.permissionDeniedUbication",
    defaultMessage: "Permission denied, please allow access to your location in the browser settings"
  },
  positionUnavailableUbication: {
    "id": "intl.errorMessage.positionUnavailableUbication",
    defaultMessage: "Position unavailable, please check your internet connection or your location"
  },
  sendNewCodeWA: {
    "id": "intl.errorMessage.sendNewCodeWA",
    defaultMessage: "We have sent a new code, please check your WhatsApp"
  },
  timeoutUbication: {
    "id": "intl.errorMessage.timeoutUbication",
    defaultMessage: "Timeout, please check your internet connection"
  },
  waDeliverymanNotFound: {
    "id": "intl.errorMessage.waDeliverymanNotFound",
    defaultMessage: "The WhatsApp number is not associated with any deliveryman"
  },
  waNumberRequired: {
    "id": "intl.errorMessage.waNumberRequired",
    defaultMessage: "WhatsApp number is required"
  },
  waWebAccessNotFound: {
    "id": "intl.errorMessage.waWebAccessNotFound",
    defaultMessage: "The access code is not valid, please select 'Send code again' to receive a new code"
  },
  waWebAccessExpired: {
    "id": "intl.errorMessage.waWebAccessExpired",
    defaultMessage: "The access code has expired, We have sent a new code, please check your WhatsApp"
  }
});
export var statusMessages = defineMessages({
  stepNamePending: {
    "id": "intl.statusMessages.stepNamePending",
    defaultMessage: "waiting for the establishment"
  },
  stepNamePendingWaiting: {
    "id": "intl.statusMessages.stepNamePendingWaiting",
    defaultMessage: "we are waiting for the establishment to respond"
  },
  stepNameDraft: {
    "id": "intl.statusMessages.stepNameDraft",
    defaultMessage: "reviewing your order"
  },
  stepNameDraftWaiting: {
    "id": "intl.statusMessages.stepNameDraftWaiting",
    defaultMessage: "the establishment is reviewing your order"
  },
  stepNameFullfilled: {
    "id": "intl.statusMessages.stepNameFullfilled",
    defaultMessage: "order accepted"
  },
  stepNameFullfilledWaiting: {
    "id": "intl.statusMessages.stepNameFullfilledWaiting",
    defaultMessage: "the establishment is preparing your order"
  },
  stepNameFullfilledWaitingShop: {
    "id": "intl.statusMessages.stepNameFullfilledWaitingShop",
    defaultMessage: "the establishment is enlisting your order"
  },
  stepNameSending: {
    "id": "intl.statusMessages.stepNameSending",
    defaultMessage: "order sent"
  },
  stepNameSendingDelivery: {
    "id": "intl.statusMessages.stepNameSendingDelivery",
    defaultMessage: "order assigned"
  },
  stepNameSendingWaiting: {
    "id": "intl.statusMessages.stepNameSendingWaiting",
    defaultMessage: "the establishment has sent its delivery man with your order"
  },
  stepNameCanceledDelivery: {
    "id": "intl.statusMessages.stepNameCanceledDelivery",
    defaultMessage: "canceled"
  },
  stepNameCanceledSending: {
    "id": "intl.statusMessages.stepNameCanceledSending",
    defaultMessage: "shipping canceled"
  },
  stepNameCanceledSendingWaiting: {
    "id": "intl.statusMessages.stepNameCanceledSendingWaiting",
    defaultMessage: "The establishment is already reviewing what happened, we will notify you as soon as it is resolved"
  },
  stepNameArrived: {
    "id": "intl.statusMessages.stepNameArrived",
    defaultMessage: "delivered man has arrived"
  },
  stepNameArrivedDelivery: {
    "id": "intl.statusMessages.stepNameArrivedDelivery",
    defaultMessage: "At the address"
  },
  stepNameArrivedWaiting: {
    "id": "intl.statusMessages.stepNameArrivedWaiting",
    defaultMessage: "the delivery man has arrived at the delivery address with your order"
  },
  stepNameUnDelivered: {
    "id": "intl.statusMessages.stepNameUnDelivered",
    defaultMessage: "undelivered"
  },
  stepNameDelivered: {
    "id": "intl.statusMessages.stepNameDelivered",
    defaultMessage: "Order delivered"
  },
  stepNameWaitingForAuth: {
    "id": "intl.statusMessages.stepNameWaitingForAuth",
    defaultMessage: "waiting for validation"
  },
  stepNameWaitingForAuthWaiting: {
    "id": "intl.statusMessages.stepNameWaitingForAuthWaiting",
    defaultMessage: "the establisment is verifying your payment"
  },
  stepNameRefundedPayment: {
    "id": "intl.statusMessages.stepNameRefundedPayment",
    defaultMessage: "refunded payment"
  },
  stepNameRefundedPaymentWaiting: {
    "id": "intl.statusMessages.stepNameRefundedPaymentWaiting",
    defaultMessage: "the payment have been refunded by the establisment"
  },
  stepNameRefusedPayment: {
    "id": "intl.statusMessages.stepNameRefusedPayment",
    defaultMessage: "refused payment"
  },
  stepNameRefusedPaymentWaiting: {
    "id": "intl.statusMessages.stepNameRefusedPaymentWaiting",
    defaultMessage: "the payment was refused by the establisment"
  },
  stepNamePaymentTimeout: {
    "id": "intl.statusMessages.stepNamePaymentTimeout",
    defaultMessage: "payment timeout"
  },
  stepNamePaymentTimeoutWaiting: {
    "id": "intl.statusMessages.stepNamePaymentTimeoutWaiting",
    defaultMessage: "Payment time is over, the establishment will call you"
  },
  stepNameWaitingPayment: {
    "id": "intl.statusMessages.stepNameWaitingPayment",
    defaultMessage: "Waiting payment"
  },
  stepNameWaitingPaymentWaiting: {
    "id": "intl.statusMessages.stepNameWaitingPaymentWaiting",
    defaultMessage: "We have sent the instructions to your WhatsApp, please follow it"
  }
});
export var prodListHeaderCommonMsg = defineMessages({
  sortOptionsClear: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsClear",
    defaultMessage: "Clear..."
  },
  sortOptionsPrice: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPrice",
    defaultMessage: "Price Low-High"
  },
  sortOptionsPriceDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPriceDsc",
    defaultMessage: "Price High-Low"
  },
  sortOptionsName: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsName",
    defaultMessage: "Name Increasing"
  },
  sortOptionsNameDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsNameDsc",
    defaultMessage: "Name Decreasing"
  },
  sortOptionsUpdatedAt: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAt",
    defaultMessage: "Last updated Ascending"
  },
  sortOptionsUpdatedAtDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc",
    defaultMessage: "Last updated Descending"
  }
});
export var paymentMessages = defineMessages({
  card: {
    "id": "intl.paymentMessages.card",
    defaultMessage: "Card"
  },
  cash: {
    "id": "intl.paymentMessages.cash",
    defaultMessage: "Cash"
  },
  nequi: {
    "id": "intl.paymentMessages.nequi",
    defaultMessage: "Nequi"
  }
});
export var paymentStatusMessages = defineMessages({
  pending: {
    "id": "intl.paymentStatusMessages.pending",
    defaultMessage: "Pending"
  },
  notCharged: {
    "id": "intl.paymentStatusMessages.notCharged",
    defaultMessage: "Not charged"
  },
  fullyCharged: {
    "id": "intl.paymentStatusMessages.fullyCharged",
    defaultMessage: "Fully charged"
  },
  refused: {
    "id": "intl.paymentStatusMessages.refused",
    defaultMessage: "Refused"
  },
  fullyRefunded: {
    "id": "intl.paymentStatusMessages.fullyRefunded",
    defaultMessage: "Fully refunded"
  }
});
export var orderStatusMessages = defineMessages({
  draft: {
    "id": "intl.orderStatusMessages.draft",
    defaultMessage: "Draft"
  },
  unfulfilled: {
    "id": "intl.orderStatusMessages.unfulfilled",
    defaultMessage: "Unfulfilled"
  },
  partiallyFulfilled: {
    "id": "intl.orderStatusMessages.partiallyFulfilled",
    defaultMessage: "Partially fulfilled"
  },
  fulfilled: {
    "id": "intl.orderStatusMessages.fulfilled",
    defaultMessage: "Fulfilled"
  },
  canceled: {
    "id": "intl.orderStatusMessages.canceled",
    defaultMessage: "Not approved"
  },
  canceledDelivery: {
    "id": "intl.orderStatusMessages.canceledDelivery",
    defaultMessage: "Canceled"
  },
  canceledFulfillment: {
    "id": "intl.orderStatusMessages.canceledFulfillment",
    defaultMessage: "Shipping canceled"
  },
  sent: {
    "id": "intl.orderStatusMessages.sent",
    defaultMessage: "Sent"
  },
  sentDelivery: {
    "id": "intl.orderStatusMessages.sentDelivery",
    defaultMessage: "Assigned"
  },
  scheduled: {
    "id": "intl.orderStatusMessages.scheduled",
    defaultMessage: "Scheduled"
  },
  pending: {
    "id": "intl.orderStatusMessages.pending",
    defaultMessage: "Pending"
  },
  arrived: {
    "id": "intl.orderStatusMessages.arrived",
    defaultMessage: "Arrived"
  },
  arrivedDelivery: {
    "id": "intl.orderStatusMessages.arrivedDelivery",
    defaultMessage: "I arrived"
  },
  delivered: {
    "id": "intl.orderStatusMessages.delivered",
    defaultMessage: "Delivered"
  },
  undelivered: {
    "id": "intl.orderStatusMessages.undelivered",
    defaultMessage: "Undelivered"
  },
  timeout: {
    "id": "intl.orderStatusMessages.timeout",
    defaultMessage: "Timeout"
  }
});
export var orderMessages = defineMessages({
  createdDate: {
    "id": "intl.orderMessages.createdDate",
    defaultMessage: "Created date"
  },
  finishedDate: {
    "id": "intl.orderMessages.finishedDate",
    defaultMessage: "Finished date"
  },
  orderDate: {
    "id": "intl.orderMessages.orderDate",
    defaultMessage: "Order date"
  }
});
export var logInMessages = defineMessages({
  acceptTyc: {
    "id": "intl.logInMessages.acceptTyc",
    defaultMessage: "Accept {tyc}"
  },
  acceptPtd: {
    "id": "intl.logInMessages.acceptPtd",
    defaultMessage: "Accept {ptd}"
  },
  alreadyReadIt: {
    "id": "intl.logInMessages.alreadyReadIt",
    defaultMessage: "I already read it (Continue)"
  },
  canNotEnterTheStore: {
    "id": "intl.logInMessages.canNotEnterTheStore",
    defaultMessage: "Oh! We have not been able to enter the store."
  },
  codeVerification: {
    "id": "intl.logInMessages.codeVerification",
    defaultMessage: "Code verification"
  },
  codeReceivedInWA: {
    "id": "intl.logInMessages.codeReceivedInWA",
    defaultMessage: "Code received in your WhatsApp"
  },
  loadingStore: {
    "id": "intl.logInMessages.loadingStore",
    defaultMessage: "Loading store"
  },
  resendCode: {
    "id": "intl.logInMessages.resendCode",
    defaultMessage: "Resend code {time}"
  },
  tradeNotFound: {
    "id": "intl.logInMessages.tradeNotFound",
    defaultMessage: "We regret to inform you that we havent find the establishment in our database."
  },
  validateCode: {
    "id": "intl.logInMessages.validateCode",
    defaultMessage: "Validate code"
  },
  waNumber: {
    "id": "intl.logInMessages.waNumber",
    defaultMessage: "WhatsApp number"
  }
});
export var neighborhoodMessages = defineMessages({
  changeNeighborhood: {
    "id": "intl.neighborhoodMessages.changeNeighborhood",
    defaultMessage: "Change neighborhood"
  },
  confirmNeighborhoodQuestion: {
    "id": "intl.neighborhoodMessages.confirmNeighborhoodQuestion",
    defaultMessage: "Do you confirm that the neighborhood where you are is {neighborhoodName} {cityName}?"
  },
  confirmNeighborhoodTitle: {
    "id": "intl.neighborhoodMessages.confirmNeighborhoodTitle",
    defaultMessage: "Confirm neighborhood"
  },
  neighborhoodWithOutCoverage: {
    "id": "intl.neighborhoodMessages.neighborhoodWithOutCoverage",
    defaultMessage: "We still do not have coverage in the {neighborhoodName} neighborhood, we will notify you when we have coverage"
  },
  notFound: {
    "id": "intl.neighborhoodMessages.notFound",
    defaultMessage: "We do not find coverage in the neighborhood {neighborhoodName}"
  },
  withOutCoverage: {
    "id": "intl.neighborhoodMessages.withOutCoverage",
    defaultMessage: "Without coverage"
  },
  writeNeighborhood: {
    "id": "intl.neighborhoodMessages.writeNeighborhood",
    defaultMessage: "Write neighborhood"
  }
});
export var easyLinkMessages = defineMessages({
  iWantToShareTheDeliveryLink: {
    "id": "intl.easyLinkMessages.iWantToShareTheDeliveryLink",
    defaultMessage: "Hello 👋, I want to share you *{trade}* delivery link in *EasyPedido*, it is very fast and easy to use 😃"
  },
  orderByCall: {
    "id": "intl.easyLinkMessages.orderByCall",
    defaultMessage: "Order by call"
  },
  orderByWhatsappBot: {
    "id": "intl.easyLinkMessages.orderByWhatsappBot",
    defaultMessage: "Order by WhatsApp (BOT)"
  },
  orderOnline: {
    "id": "intl.easyLinkMessages.orderOnline",
    defaultMessage: "Order online"
  }
});
export var alertMessages = defineMessages({
  addressIsNotInNeighborhoodIsInOther: {
    "id": "intl.alertMessages.addressIsNotInNeighborhoodIsInOther",
    defaultMessage: "The address provided {isNotFound} in {currentNeighborhood}, really it is in the neighborhood {newNeighborhood}"
  },
  addressIsNotInNeighborhoodIsInOtherTradeDoesHaveCoverage: {
    "id": "intl.alertMessages.addressIsNotInNeighborhoodIsInOtherTradeDoesHaveCoverage",
    defaultMessage: "The address provided {isNotFound} in {currentNeighborhood}, really it is in the neighborhood {newNeighborhood} where the establishment {dontHaveCoverage}"
  },
  changeNeighborhood: {
    "id": "intl.alertMessages.changeNeighborhood",
    defaultMessage: "Do you want to change the neighborhood to {newNeighborhood}?"
  },
  checkYourAddressAndTryAgain: {
    "id": "intl.alertMessages.checkYourAddressAndTryAgain",
    defaultMessage: "Check your address and try again"
  },
  neighborhoodIsServedByOtherTrade: {
    "id": "intl.alertMessages.neighborhoodIsServedByOtherTrade",
    defaultMessage: "The neighborhood {newNeighborhood} is served by other trade, when change you have to select the products again"
  }
});
export function translatePaymentStatus(status, intl) {
  switch (status) {
    case "PENDING":
      return intl.formatMessage(paymentStatusMessages.pending);

    case "NOT_CHARGED":
      return intl.formatMessage(paymentStatusMessages.notCharged);

    case "FULLY_CHARGED":
      return intl.formatMessage(paymentStatusMessages.fullyCharged);

    case "REFUSED":
      return intl.formatMessage(paymentStatusMessages.refused);

    case "FULLY_REFUNDED":
      return intl.formatMessage(paymentStatusMessages.fullyRefunded);

    default:
      return status;
  }
}
export function translateOrderStatus(status, intl) {
  switch (status) {
    case "SCHEDULED":
      return intl.formatMessage(orderStatusMessages.scheduled);

    case "DRAFT":
      return intl.formatMessage(orderStatusMessages.draft);

    case "Unfulfilled":
      return intl.formatMessage(orderStatusMessages.unfulfilled);

    case "Partially fulfilled":
      return intl.formatMessage(orderStatusMessages.partiallyFulfilled);

    case "FULFILLED":
      return intl.formatMessage(orderStatusMessages.fulfilled);

    case "CANCELED":
      return intl.formatMessage(orderStatusMessages.canceled);

    case "SENT":
      return intl.formatMessage(orderStatusMessages.sent);

    case "PENDING":
      return intl.formatMessage(orderStatusMessages.pending);

    case "ARRIVED":
      return intl.formatMessage(orderStatusMessages.arrived);

    case "DELIVERED":
      return intl.formatMessage(orderStatusMessages.delivered);

    case "UNDELIVERED":
      return intl.formatMessage(orderStatusMessages.undelivered);

    case "TIMEOUT":
      return intl.formatMessage(orderStatusMessages.timeout);

    case "CANCELED_FULFILLMENT":
      return intl.formatMessage(orderStatusMessages.canceledFulfillment);

    case "WAITING_PAYMENT":
      return intl.formatMessage(statusMessages.stepNameWaitingPayment);

    case "PAYMENT_TIMEOUT":
      return intl.formatMessage(statusMessages.stepNamePaymentTimeout);

    case "WAITING_FOR_AUTH":
      return intl.formatMessage(statusMessages.stepNameWaitingForAuth);

    case "REFUSED_PAYMENT":
      return intl.formatMessage(statusMessages.stepNameRefusedPayment);

    default:
      return status;
  }
}
export function translateOrderStatusDelivery(status, intl) {
  switch (status) {
    case "DRAFT":
      return intl.formatMessage(orderStatusMessages.draft);

    case "Unfulfilled":
      return intl.formatMessage(orderStatusMessages.unfulfilled);

    case "Partially fulfilled":
      return intl.formatMessage(orderStatusMessages.partiallyFulfilled);

    case "FULFILLED":
      return intl.formatMessage(orderStatusMessages.fulfilled);

    case "CANCELED":
      return intl.formatMessage(orderStatusMessages.canceledDelivery);

    case "SENT":
      return intl.formatMessage(orderStatusMessages.sentDelivery);

    case "PENDING":
      return intl.formatMessage(orderStatusMessages.pending);

    case "ARRIVED":
      return intl.formatMessage(orderStatusMessages.arrivedDelivery);

    case "DELIVERED":
      return intl.formatMessage(orderStatusMessages.delivered);

    case "UNDELIVERED":
      return intl.formatMessage(orderStatusMessages.undelivered);

    case "TIMEOUT":
      return intl.formatMessage(orderStatusMessages.timeout);

    case "CANCELED_FULFILLMENT":
      return intl.formatMessage(orderStatusMessages.canceledFulfillment);

    default:
      return status;
  }
}
export function getErrorMessage(code, intl) {
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    minutesToUnlock: 0
  };

  switch (code) {
    case AccountErrorCode.CODE_REQUIRED:
      return intl.formatMessage(errorMessage.codeRequired);

    case AccountErrorCode.CUSTOMER_NOT_FOUND:
      return intl.formatMessage(errorMessage.customerNotFound);

    case AccountErrorCode.CUSTOMER_NOT_ACTIVE:
      return intl.formatMessage(errorMessage.customerNotActive);

    case AccountErrorCode.ECOMMERCE_WEB_ACCESS_MAX_ATTEMPTS:
      return intl.formatMessage(errorMessage.exceededMaximumNumberOfAttempts, {
        minute: params.minutesToUnlock.toFixed(0)
      });

    case AccountErrorCode.NOT_ACCESS_TERMS_AND_CONDITIONS:
      return intl.formatMessage(errorMessage.notAccessTermsAndConditions);

    case AccountErrorCode.NEIGHBORHOOD_DOES_NOT_HAVE_COVERAGE:
      return intl.formatMessage(errorMessage.neighborhoodDoesNotHaveCoverage);

    case AccountErrorCode.SEND_NEW_CODE_WA:
      return intl.formatMessage(errorMessage.sendNewCodeWA);

    case AccountErrorCode.WA_NUMBER_REQUIRED:
      return intl.formatMessage(errorMessage.waNumberRequired);

    case AccountErrorCode.WA_WEB_ACCESS_NOT_FOUND:
      return intl.formatMessage(errorMessage.waWebAccessNotFound);

    case AccountErrorCode.WA_WEB_ACCESS_EXPIRED:
      return intl.formatMessage(errorMessage.waWebAccessExpired);

    case AccountErrorCode.DELIVERYMAN_NOT_FOUND:
      return intl.formatMessage(errorMessage.waDeliverymanNotFound);

    case UbicationErrorCode.PERMISSION_DENIED:
      return intl.formatMessage(errorMessage.permissionDeniedUbication);

    case UbicationErrorCode.POSITION_UNAVAILABLE:
      return intl.formatMessage(errorMessage.positionUnavailableUbication);

    case UbicationErrorCode.TIMEOUT:
      return intl.formatMessage(errorMessage.timeoutUbication);

    case UbicationErrorCode.GEOLOCATION_ERROR:
      return intl.formatMessage(errorMessage.timeoutUbication);

    case UbicationErrorCode.NEIGHBORHOOD_NOT_FOUND:
      return intl.formatMessage(errorMessage.neighborhoodNotFound);

    default:
      return code;
  }
}