import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { useIntl } from "react-intl";
import { useNavigate, Link } from "react-router-dom";
import { Icon } from "@components/atoms";
import { UserRoleEnum } from "@globalTypes/globalTypes";
import { useHandlerWhenClickedOutside } from "@hooks";
import { commonMessages } from "@temp/intl";
import { useAuth } from "@saleor/sdk";
import * as S from "./styles";
import * as paths from "../../../../app/routes/paths";
export var AccountMenuMobile = function AccountMenuMobile(_ref) {
  var links = _ref.links,
      active = _ref.active;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showMenu = _React$useState2[0],
      setShowMenu = _React$useState2[1];

  var intl = useIntl();

  var _useAuth = useAuth(),
      user = _useAuth.user,
      signOut = _useAuth.signOut;

  var _useHandlerWhenClicke = useHandlerWhenClickedOutside(function () {
    setShowMenu(false);
  }),
      setElementRef = _useHandlerWhenClicke.setElementRef;

  var navigate = useNavigate();

  var linkToMenuItem = function linkToMenuItem(link) {
    link = link.replace(/\//g, "").replace("-", " ").split(" ").map(function (s) {
      return s.charAt(0).toUpperCase() + s.substring(1);
    }).join(" ");
    var menuItem = link;
    /* eslint-disable default-case */

    switch (link) {
      case "Account":
        menuItem = intl.formatMessage(commonMessages.profile);
        break;

      case "Order History":
        menuItem = intl.formatMessage(commonMessages.orderHistory);
        break;

      case "Address Book":
        menuItem = intl.formatMessage(commonMessages.addressBook);
        break;

      case "Delivery":
        menuItem = intl.formatMessage(commonMessages.assignedOrders);
        break;
    }

    return menuItem;
  };

  var handleSignOut = function handleSignOut() {
    var _user$role;

    var userRole = user === null || user === void 0 ? void 0 : (_user$role = user.role) === null || _user$role === void 0 ? void 0 : _user$role.toUpperCase();
    signOut();

    if (userRole === UserRoleEnum.DELIVERY_MAN) {
      navigate(paths.deliveryUrl);
    } else {
      navigate("#");
    }
  };

  return React.createElement(S.Wrapper, {
    onClick: function onClick() {
      setShowMenu(true);
    },
    ref: setElementRef()
  }, linkToMenuItem(active), React.createElement(Icon, {
    name: "select_arrow",
    size: 8
  }), showMenu && React.createElement(S.Overlay, null, links.map(function (link) {
    var menuItem = linkToMenuItem(link);
    return React.createElement("div", {
      onClick: function onClick(evt) {
        evt.stopPropagation();
        setShowMenu(false);
      },
      key: link
    }, React.createElement(Link, {
      to: link
    }, React.createElement(S.MenuItem, {
      active: active === link
    }, menuItem, React.createElement(Icon, {
      name: "select_arrow",
      size: 8
    }))));
  }), React.createElement("button", {
    onClick: handleSignOut
  }, React.createElement(S.MenuItem, {
    active: false
  }, "Cerrar sesi\xF3n", React.createElement(Icon, {
    name: "select_arrow",
    size: 8
  })))));
};