import "./scss/styles.scss";
import principleLogo from "@temp/images/delivery/easypedidologopurple.svg";
import backgroundMobile from "@temp/images/ecommerce/login/backgroundMobileWithOutMoped.svg";
import backgroundMobileDeliveryman from "@temp/images/delivery/backgroundMobileLoginDeliveryman.svg";
import backgroundWhite from "@temp/images/ecommerce/login/backgroundWhite.svg";
import backgroundWhiteDeliveryman from "@temp/images/delivery/backgroundWhiteLoginDeliveryman.svg";
import backgroundWhiteWrongTrade from "@temp/images/ecommerce/login/backgroundWhiteWrongTrade.jpg";
import redMoped from "@temp/images/ecommerce/login/moped.svg";
import wrongTrade from "@temp/images/ecommerce/login/wrongTrade.svg";
import React from "react";
import Media from "react-responsive";
import { xMediumScreen } from "@styles/constants";
import { Loader, LoginManager } from "@temp/components";
import { useIntl } from "react-intl";
import { commonMessages, logInMessages } from "@temp/intl";
import ReactSVG from "react-svg";
export var LayoutLoginManager = function LayoutLoginManager(_ref) {
  var accessCodeSeconds = _ref.accessCodeSeconds,
      disabledAccessButton = _ref.disabledAccessButton,
      showValidateCode = _ref.showValidateCode,
      defaultCountryCode = _ref.defaultCountryCode,
      userWaNumber = _ref.userWaNumber,
      setUserWaNumber = _ref.setUserWaNumber,
      errorsVerifyCustomerAccount = _ref.errorsVerifyCustomerAccount,
      seeCode = _ref.seeCode,
      errorsValidateTradeCode = _ref.errorsValidateTradeCode,
      code = _ref.code,
      resendCode = _ref.resendCode,
      siteSettings = _ref.siteSettings,
      showWrongTrade = _ref.showWrongTrade,
      validateTradeCodeDataLoading = _ref.validateTradeCodeDataLoading,
      verifyTradeStaffAccountDataLoading = _ref.verifyTradeStaffAccountDataLoading,
      tradeName = _ref.tradeName,
      handleChange = _ref.handleChange,
      handleCodeInput = _ref.handleCodeInput,
      handleSeeCode = _ref.handleSeeCode,
      handleValidateCode = _ref.handleValidateCode,
      handleReSendCode = _ref.handleReSendCode,
      handleLogIn = _ref.handleLogIn,
      userHasAcceptedTyc = _ref.userHasAcceptedTyc,
      loading = _ref.loading,
      tradeLogo = _ref.tradeLogo,
      isDeliveryman = _ref.isDeliveryman;
  var intl = useIntl();
  return React.createElement("main", {
    className: "layoutLoginManager"
  }, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, function (matches) {
    return matches ? React.createElement("img", {
      className: "layoutLoginManager__background",
      alt: "",
      src: isDeliveryman ? backgroundMobileDeliveryman : backgroundMobile
    }) : React.createElement("img", {
      className: "layoutLoginManager__background",
      alt: "",
      src: isDeliveryman ? backgroundWhiteDeliveryman : showWrongTrade ? backgroundWhiteWrongTrade : backgroundWhite
    });
  }), React.createElement("section", {
    className: "layoutLoginManager__left"
  }, showWrongTrade ? React.createElement("div", {
    className: "layoutLoginManager__left__error"
  }, React.createElement("p", null, intl.formatMessage(logInMessages.tradeNotFound))) : React.createElement("div", {
    className: "layoutLoginManager__left__content"
  }, React.createElement("div", {
    className: "layoutLoginManager__left__content__logo"
  }, React.createElement("img", {
    alt: isDeliveryman ? "Logo EasyPedido" : tradeLogo === null || tradeLogo === void 0 ? void 0 : tradeLogo.alt,
    src: isDeliveryman ? principleLogo : tradeLogo === null || tradeLogo === void 0 ? void 0 : tradeLogo.url
  })), React.createElement("p", {
    className: "layoutLoginManager__left__content__name"
  }, tradeName), React.createElement("form", {
    className: "layoutLoginManager__left__content__form"
  }, React.createElement(Media, {
    maxWidth: xMediumScreen
  }, function (matches) {
    return matches && React.createElement(ReactSVG, {
      path: redMoped
    });
  }), React.createElement(LoginManager, {
    accessCodeSeconds: accessCodeSeconds,
    buttonAccessLabel: intl.formatMessage(commonMessages.toAccess),
    disabledAccessButton: disabledAccessButton,
    classNameButton: "layoutLoginManager__left__content__logInButton",
    code: code,
    defaultCountryCode: defaultCountryCode,
    errorsValidateCode: errorsValidateTradeCode,
    errorsVerifyAccount: errorsVerifyCustomerAccount,
    resendCode: resendCode,
    seeCode: seeCode,
    showValidateCode: showValidateCode,
    siteSettings: siteSettings,
    userHasAcceptedTyc: userHasAcceptedTyc,
    userWaNumber: userWaNumber,
    handleChange: handleChange,
    handleCodeInput: handleCodeInput,
    handleLogIn: handleLogIn,
    handleReSendCode: handleReSendCode,
    handleSeeCode: handleSeeCode,
    handleValidateCode: handleValidateCode,
    setUserWaNumber: setUserWaNumber
  })))), showWrongTrade && React.createElement(Media, {
    minWidth: xMediumScreen
  }, React.createElement("section", {
    className: "layoutLoginManager__right"
  }, React.createElement("img", {
    alt: "",
    className: "layoutLoginManager__right__background",
    src: wrongTrade
  }))), (verifyTradeStaffAccountDataLoading || validateTradeCodeDataLoading || loading) && React.createElement("div", {
    className: "layoutLoginManager__loading"
  }, React.createElement(Loader, null)));
};
export default LayoutLoginManager;