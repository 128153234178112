import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Link, useNavigate, useLocation, NavLink } from "react-router-dom";
import Media from "react-responsive";
import ReactSVG from "react-svg"; // import { smallScreen } from "@styles/constants";

import { Money } from "@components/containers";
import { CachedImage } from "@components/molecules";
import { useAuth, useCart } from "@saleor/sdk";
import { xMediumScreen, xxMediumScreen } from "@styles/constants";
import { CurrentTradeDetailsContext } from "@temp/contexts";
import { checkoutMessages, commonMessages } from "@temp/intl";
import { useAppDispatch, useAppSelector, updateDeliveryNeighborhoodName, updateDeliveryPrice } from "@temp/redux";
import { useGoBack } from "@temp/utils";
import { Offline, Online, OverlayContext, OverlayTheme, OverlayType, SearchHeaderMobile } from "..";
import * as appPaths from "../../app/routes";
import { maybe } from "../../core/utils";
import admiracion from "../../images/admiracion.svg";
import cartImg from "../../images/cart.svg";
import profileImage from "../../images/myprofile.svg";
import hamburgerImg from "../../images/main/menu.svg";
import logoImg from "../../images/logo.svg";
import helpImg from "../../images/main/helpImg.svg";
import locationImg from "../../images/delivery/location.svg";
import profileImg from "../../images/profilemenu.svg";
import profileWhiteImg from "../../images/main/mainProfile.svg";
import gobackImg from "../../images/goback.svg";
import whiteArrowImg from "../../images/main/whiteArrow.svg";
import { xlargeScreen, mediumScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";
import AlertHelp from "../AlertHelp";
import AlertMap from "../AlertMap";
import * as paths from "../../app/routes/paths";
import { baseUrl } from "../../app/routes/paths";
import { baseDeliveryUrl } from "../../app/routes";
import AlertLogin from "../AlertLogin";
var finishedStates = ["DELIVERED", "UNDELIVERED", "CANCELED", "CANCELED_FULFILLMENT"];
var urlsToSearchCurrentTrade = ["category", "sale", "subcategory", "shipping", "review", "payment"];
var MainMenu = React.memo(function (_ref) {
  var _user$firstName, _user$defaultShipping3, _user$currentTrade7, _user$currentTrade8, _user$currentTrade8$n, _user$currentTrade9, _user$currentTrade10, _user$currentTrade10$, _user$currentTrade11, _user$currentTrade11$, _user$currentTrade12, _user$currentTrade13, _user$currentTrade14, _user$currentTrade15, _user$currentTrade16, _customerAddress$city;

  var goback = _ref.goback,
      search = _ref.search,
      cart = _ref.cart,
      cartCheckout = _ref.cartCheckout,
      currentOrderState = _ref.currentOrderState,
      customerAddress = _ref.customerAddress,
      deliveryAccess = _ref.deliveryAccess,
      mobileCartOpened = _ref.mobileCartOpened,
      differentTradeToActual = _ref.differentTradeToActual,
      orderStatus = _ref.orderStatus,
      orderNumber = _ref.orderNumber,
      tradeLogo = _ref.tradeLogo,
      tradeNumber = _ref.tradeNumber,
      tradeName = _ref.tradeName,
      gobackAddress = _ref.gobackAddress,
      gobackDelivery = _ref.gobackDelivery,
      gobackAssignedOrders = _ref.gobackAssignedOrders,
      gobackNeighborhoods = _ref.gobackNeighborhoods,
      gobackStatus = _ref.gobackStatus,
      gobackHome = _ref.gobackHome,
      gobackProduct = _ref.gobackProduct,
      gobackSubCategoriesCategory = _ref.gobackSubCategoriesCategory,
      gobackCategoriesSale = _ref.gobackCategoriesSale,
      setMobileCartOpened = _ref.setMobileCartOpened,
      gobackCategoryRedirectSale = _ref.gobackCategoryRedirectSale;
  var overlayContext = useContext(OverlayContext);

  var _useContext = useContext(CurrentTradeDetailsContext),
      dataCurrentTrade = _useContext.data;

  var dispatch = useAppDispatch();

  var _useAppSelector = useAppSelector(function (state) {
    return state;
  }),
      deliveryPrice = _useAppSelector.deliveryCustomer.deliveryPrice,
      _useAppSelector$trade = _useAppSelector.tradeAttention,
      dayName = _useAppSelector$trade.dayName,
      fromMinute = _useAppSelector$trade.fromMinute,
      fromHour = _useAppSelector$trade.fromHour,
      hasAttentionAtCurrentTime = _useAppSelector$trade.hasAttentionAtCurrentTime,
      _useAppSelector$searc = _useAppSelector.search,
      searchCategory = _useAppSelector$searc.searchCategory,
      searchSubcategory = _useAppSelector$searc.searchSubcategory;

  var _useCart = useCart(),
      items = _useCart.items,
      totalPrice = _useCart.totalPrice;

  var _useAuth = useAuth(),
      user = _useAuth.user,
      signOut = _useAuth.signOut;

  var intl = useIntl();
  var location = useLocation();
  var navigate = useNavigate();

  var _useGoBack = useGoBack(),
      goBackCategoriesSale = _useGoBack.goBackCategoriesSale,
      goBackSubCategoriesCategory = _useGoBack.goBackSubCategoriesCategory,
      goBackCategoryRedirectSale = _useGoBack.goBackCategoryRedirectSale;

  var firstName = (user === null || user === void 0 ? void 0 : (_user$firstName = user.firstName) === null || _user$firstName === void 0 ? void 0 : _user$firstName.split(" ")) || [""];
  var cartItemsQuantity = items && items.reduce(function (acc, item) {
    return acc + (item === null || item === void 0 ? void 0 : item.quantity);
  }, 0);

  var _useState = useState(""),
      _useState2 = _slicedToArray(_useState, 2),
      googleMapsUrl = _useState2[0],
      setGoogleMapsUrls = _useState2[1];

  var _useState3 = useState(""),
      _useState4 = _slicedToArray(_useState3, 2),
      wazeUrl = _useState4[0],
      setWazeUrl = _useState4[1];

  var _useState5 = useState(""),
      _useState6 = _slicedToArray(_useState5, 2),
      viewHour = _useState6[0],
      setViewHour = _useState6[1];

  var _useState7 = useState(""),
      _useState8 = _slicedToArray(_useState7, 2),
      searchDeliveryName = _useState8[0],
      setSearchDeliveryName = _useState8[1];

  var _useState9 = useState(true),
      _useState10 = _slicedToArray(_useState9, 2),
      loadingCurrentTradeState = _useState10[0],
      setLoadingCurrentTradeState = _useState10[1];

  var _useState11 = useState(false),
      _useState12 = _slicedToArray(_useState11, 2),
      showProfileDetail = _useState12[0],
      setShowProfileDetail = _useState12[1];

  var _useState13 = useState(false),
      _useState14 = _slicedToArray(_useState13, 2),
      showAlertHelp = _useState14[0],
      setShowAlertHelp = _useState14[1];

  var _useState15 = useState(false),
      _useState16 = _slicedToArray(_useState15, 2),
      showAlertMap = _useState16[0],
      setShowAlertMap = _useState16[1];

  var _useState17 = useState(false),
      _useState18 = _slicedToArray(_useState17, 2),
      showAlertLogin = _useState18[0],
      setShowAlertLogin = _useState18[1];

  var _useState19 = useState(undefined),
      _useState20 = _slicedToArray(_useState19, 1),
      activeDropdown = _useState20[0]; // setActiveDropdown(undefined);


  var variableConditional = React.useMemo(function () {
    return !deliveryAccess && !hasAttentionAtCurrentTime && (user === null || user === void 0 ? void 0 : user.currentTrade) !== null && (location === null || location === void 0 ? void 0 : location.pathname) !== "/order-history/" && (location === null || location === void 0 ? void 0 : location.pathname) !== "/address-book/" && (location === null || location === void 0 ? void 0 : location.pathname) !== "/address/edit" && (location === null || location === void 0 ? void 0 : location.pathname) !== "/account/" && (location === null || location === void 0 ? void 0 : location.pathname) !== "/order-history/".concat(localStorage.getItem("order_token")) && (location === null || location === void 0 ? void 0 : location.pathname) !== "/statusl/fulfilled" && (location === null || location === void 0 ? void 0 : location.pathname) !== "/pstatusp/canceled" && (location === null || location === void 0 ? void 0 : location.pathname) !== "/pstatusp/timeout" && (location === null || location === void 0 ? void 0 : location.pathname) !== "/pstatusp/delivered" && (location === null || location === void 0 ? void 0 : location.pathname) !== "/pstatusp/undelivered" && (location === null || location === void 0 ? void 0 : location.pathname) !== "/statusl/arrived" && (location === null || location === void 0 ? void 0 : location.pathname) !== "/statusl/sent" && (location === null || location === void 0 ? void 0 : location.pathname) !== "/statusl/pending" && (location === null || location === void 0 ? void 0 : location.pathname) !== "/statusl/draft";
  }, [deliveryAccess, hasAttentionAtCurrentTime, location === null || location === void 0 ? void 0 : location.pathname, user === null || user === void 0 ? void 0 : user.currentTrade]);
  var style = React.useMemo(function () {
    if (variableConditional) {
      return "header-with-dropdown_alert_opening";
    }

    return "header-with-dropdown";
  }, [variableConditional]);
  var goBackCheckout = React.useCallback(function () {
    if (window.location.pathname.includes("checkout")) {
      var checkoutPathname = ["/", "/checkout/address", "/checkout/shipping", "/checkout/payment", "/checkout/review"];

      for (var i = 0; i <= checkoutPathname.length; i++) {
        if (window.location.pathname === checkoutPathname[i]) {
          navigate(checkoutPathname[i - 1]);
        }
      }
    } else {
      navigate(-1);
    }
  }, []);
  var goBackStatus = React.useCallback(function () {
    if (localStorage.getItem("flag_progress")) {
      navigate("/order-in-progress/");
    } else if (localStorage.getItem("flag_history")) {
      navigate("/order-history/");
    } else {
      window.location.href = baseUrl;
    }
  }, []);
  var goBackDelivery = React.useCallback(function (currentOrderState) {
    if (finishedStates.includes(currentOrderState === null || currentOrderState === void 0 ? void 0 : currentOrderState.toUpperCase())) {
      navigate("/delivery/finished");
    } else {
      navigate("/delivery");
    }
  }, []);
  var goBackProduct = React.useCallback(function () {
    var urlCheckout = localStorage.getItem("checkout-modify") === "true";
    var urlProduct = localStorage.getItem("product_url");

    if (urlCheckout) {
      navigate("/checkout");
    } else if (urlProduct) {
      navigate(urlProduct);
    } else {
      navigate(baseUrl);
    }
  }, []);
  var goBackNeighborhoods = React.useCallback(function () {
    if (localStorage.getItem("fromProductsToNeighborhoods") === "true") {
      navigate("/");
    } else if (localStorage.getItem("mainTrade") !== null) {
      var _JSON$parse, _JSON$parse$mainTrade;

      var slug = (_JSON$parse = JSON.parse(localStorage.getItem("mainTrade") || "{}")) === null || _JSON$parse === void 0 ? void 0 : (_JSON$parse$mainTrade = _JSON$parse.mainTradeData) === null || _JSON$parse$mainTrade === void 0 ? void 0 : _JSON$parse$mainTrade.slug;
      navigate("/web/".concat(slug));
    } else {
      signOut();
    }
  }, []);
  var handleSignOut = React.useCallback(function () {
    signOut();
    navigate("#");
  }, []);
  var showMap = React.useCallback(function (customerAddress) {
    var userAgent = navigator.userAgent || navigator.vendor;
    var urlDir = "";
    var urlDirDesktop = "";
    var urlIosWaze = "";

    if (customerAddress.latitude && customerAddress.latitude !== 0 && customerAddress.latitude !== "0" && customerAddress.longitude && customerAddress.longitude !== 0 && customerAddress.longitude !== "0") {
      var newLatitude = parseFloat(customerAddress.latitude.toString().replace(/,/g, "."));
      var newLongitude = parseFloat(customerAddress.longitude.toString().replace(/,/g, "."));
      urlDir = "geo:".concat(newLatitude, ",").concat(newLongitude, "?q=").concat(newLatitude, ",").concat(newLongitude);
      urlDirDesktop = "https://www.google.com/maps/search/?api=1&query=".concat(newLatitude, ",").concat(newLongitude);
      urlIosWaze = "https://www.waze.com/ul?ll=".concat(newLatitude, ",").concat(newLongitude, "&navigate=yes");
    } else {
      urlDir = "geo:0,0?q=".concat(customerAddress === null || customerAddress === void 0 ? void 0 : customerAddress.streetAddress1, ", ").concat(customerAddress.city2.name, ", ").concat(customerAddress.region2.name, ", ").concat(customerAddress.country2.name);
      urlDirDesktop = "https://www.google.com/maps/search/?api=1&query=".concat(customerAddress === null || customerAddress === void 0 ? void 0 : customerAddress.streetAddress1, ", ").concat(customerAddress.city2.name, ", ").concat(customerAddress.region2.name, ", ").concat(customerAddress.country2.name);
      urlIosWaze = "https://waze.com/ul?q=".concat(customerAddress === null || customerAddress === void 0 ? void 0 : customerAddress.streetAddress1, ", ").concat(customerAddress.city2.name, ", ").concat(customerAddress.region2.name, ", ").concat(customerAddress.country2.name, "&navigate=yes");
    }

    if (/android/i.test(userAgent)) {
      urlDir = urlDir.replace(/#/g, "");
      window.open(urlDir, "_system", "location=yes");
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      urlDirDesktop = urlDirDesktop.replace(/#/g, "");
      setGoogleMapsUrls(urlDirDesktop);
      urlIosWaze = urlIosWaze.replace(/#/g, "");
      setWazeUrl(urlIosWaze);
      setShowAlertMap(true);
    } else {
      urlDirDesktop = urlDirDesktop.replace(/#/g, "");
      window.open(urlDirDesktop);
    }
  }, []);
  var goBackAddress = React.useCallback(function () {
    var addressUndefined = localStorage.getItem("address-undefined");

    if (addressUndefined === "true") {
      navigate(baseUrl);
    } else {
      navigate(-1);
    }
  }, []);

  if (!(user === null || user === void 0 ? void 0 : user.currentTrade)) {
    gobackHome = false;
  }

  useEffect(function () {
    var _dataCurrentTrade$cur, _dataCurrentTrade$cur2;

    var shippingMethods = (dataCurrentTrade === null || dataCurrentTrade === void 0 ? void 0 : (_dataCurrentTrade$cur = dataCurrentTrade.currentShippingMethods) === null || _dataCurrentTrade$cur === void 0 ? void 0 : _dataCurrentTrade$cur.length) && (dataCurrentTrade === null || dataCurrentTrade === void 0 ? void 0 : (_dataCurrentTrade$cur2 = dataCurrentTrade.currentShippingMethods) === null || _dataCurrentTrade$cur2 === void 0 ? void 0 : _dataCurrentTrade$cur2.length) > 0 ? dataCurrentTrade === null || dataCurrentTrade === void 0 ? void 0 : dataCurrentTrade.currentShippingMethods : [];

    if (!location.pathname.includes("delivery")) {
      var _user$defaultShipping, _user$defaultShipping2;

      if (totalPrice && shippingMethods && shippingMethods.length > 1) {
        shippingMethods.forEach(function (shippingMethod) {
          var _shippingMethod$minim, _totalPrice$net, _totalPrice$net2;

          if ((shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.minimumOrderPrice) && (shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.maximumOrderPrice) && (shippingMethod === null || shippingMethod === void 0 ? void 0 : (_shippingMethod$minim = shippingMethod.minimumOrderPrice) === null || _shippingMethod$minim === void 0 ? void 0 : _shippingMethod$minim.amount) <= (totalPrice === null || totalPrice === void 0 ? void 0 : (_totalPrice$net = totalPrice.net) === null || _totalPrice$net === void 0 ? void 0 : _totalPrice$net.amount) && (totalPrice === null || totalPrice === void 0 ? void 0 : (_totalPrice$net2 = totalPrice.net) === null || _totalPrice$net2 === void 0 ? void 0 : _totalPrice$net2.amount) <= (shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.maximumOrderPrice.amount) || (shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.minimumOrderPrice) && !(shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.maximumOrderPrice) && (shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.minimumOrderPrice.amount) <= totalPrice.net.amount) {
            dispatch(updateDeliveryPrice({
              deliveryPrice: {
                amount: shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.price.amount,
                currency: shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.price.currency
              }
            }));
          }
        });
      } else if (shippingMethods && shippingMethods.length === 1) {
        var _shippingMethods$, _shippingMethods$2;

        dispatch(updateDeliveryPrice({
          deliveryPrice: {
            amount: (_shippingMethods$ = shippingMethods[0]) === null || _shippingMethods$ === void 0 ? void 0 : _shippingMethods$.price.amount,
            currency: (_shippingMethods$2 = shippingMethods[0]) === null || _shippingMethods$2 === void 0 ? void 0 : _shippingMethods$2.price.currency
          }
        }));
      }

      dispatch(updateDeliveryNeighborhoodName({
        deliveryNeighborhoodName: user === null || user === void 0 ? void 0 : (_user$defaultShipping = user.defaultShippingAddress) === null || _user$defaultShipping === void 0 ? void 0 : (_user$defaultShipping2 = _user$defaultShipping.neighborhood) === null || _user$defaultShipping2 === void 0 ? void 0 : _user$defaultShipping2.name
      }));
    }
  }, [dataCurrentTrade, dataCurrentTrade === null || dataCurrentTrade === void 0 ? void 0 : dataCurrentTrade.currentShippingMethods, location.pathname, totalPrice, user === null || user === void 0 ? void 0 : (_user$defaultShipping3 = user.defaultShippingAddress) === null || _user$defaultShipping3 === void 0 ? void 0 : _user$defaultShipping3.neighborhood]);
  useEffect(function () {
    if (location.pathname.includes("checkout") && localStorage.getItem("checkout-modify") === "true") {
      setMobileCartOpened(true);
      localStorage.removeItem("checkout-modify");
    }

    if ((location.pathname.includes(urlsToSearchCurrentTrade[0]) || location.pathname.includes(urlsToSearchCurrentTrade[1]) || location.pathname.includes(urlsToSearchCurrentTrade[2]) || location.pathname.includes(urlsToSearchCurrentTrade[3]) || location.pathname.includes(urlsToSearchCurrentTrade[4]) || location.pathname.includes(urlsToSearchCurrentTrade[5]) || location.pathname === "/") && !loadingCurrentTradeState) {
      setLoadingCurrentTradeState(true);
    }
  }, [location.pathname]);
  useEffect(function () {
    if (activeDropdown) {
      overlayContext.show(OverlayType.mainMenuNav, OverlayTheme.modal);
    } else {
      overlayContext.hide();
    }

    return function () {
      overlayContext.hide();
    };
  }, [activeDropdown]);
  useEffect(function () {
    if (fromHour && fromHour > 12) {
      var countHour = 1;

      for (var i = 13; i < 24; i++) {
        if (fromHour === i) {
          if (fromHour === 22 || fromHour === 23) {
            setViewHour("".concat(countHour));
          } else {
            setViewHour("0".concat(countHour));
          }

          break;
        }

        countHour++;
      }
    }

    if (fromHour && fromHour >= 10 && fromHour <= 12) {
      setViewHour("".concat(fromHour));
    }

    if (fromHour && fromHour < 10 || !fromHour) {
      setViewHour("0".concat(fromHour));
    }

    return function () {
      setViewHour("0");
    };
  }, [fromHour]);
  useEffect(function () {
    if (location.pathname.includes("/category/") && searchCategory.name !== "" && searchCategory.name.toUpperCase() !== intl.formatMessage(commonMessages.all).toUpperCase()) {
      setSearchDeliveryName(searchCategory.name);
    } else if (location.pathname.includes("/subcategory/") && searchSubcategory.name !== "" && searchSubcategory.name.toUpperCase() !== intl.formatMessage(commonMessages.all).toUpperCase()) {
      setSearchDeliveryName(searchSubcategory.name);
    } else {
      setSearchDeliveryName("");
    }
  }, [searchCategory, searchSubcategory]);
  useEffect(function () {
    var timeOut;

    if (loadingCurrentTradeState && (dataCurrentTrade === null || dataCurrentTrade === void 0 ? void 0 : dataCurrentTrade.currentShippingMethods)) {
      timeOut = setTimeout(function () {
        setLoadingCurrentTradeState(false);
      }, 700);
    }

    return function () {
      return clearTimeout(timeOut);
    };
  }, [dataCurrentTrade]);
  return React.createElement(React.Fragment, null, React.createElement("header", {
    className: "".concat(style, " headerMainMenu"),
    style: showProfileDetail ? {
      zIndex: 100
    } : {}
  }, React.createElement("nav", {
    className: "main-menu",
    id: "header",
    style: variableConditional ? {
      position: "relative",
      top: "31px"
    } : {}
  }, variableConditional && React.createElement("div", {
    className: "alertOpen",
    style: !hasAttentionAtCurrentTime ? {
      position: "fixed",
      top: "0"
    } : {}
  }, React.createElement(ReactSVG, {
    className: "alertOpen__svg",
    path: admiracion
  }), dayName ? React.createElement("p", {
    className: "alertOpen__textHour"
  }, React.createElement(FormattedMessage, {
    id: "components.MainMenu.MainMenu.565938148",
    defaultMessage: "Open from"
  }), " ", dayName, " ", React.createElement(FormattedMessage, {
    id: "components.MainMenu.MainMenu.4261826499",
    defaultMessage: "at"
  }), " ", viewHour, ":", fromMinute <= 9 ? React.createElement(React.Fragment, null, "0", fromMinute) : React.createElement(React.Fragment, null, fromMinute), React.createElement("span", null, " "), fromHour >= 12 ? React.createElement(FormattedMessage, {
    id: "components.MainMenu.MainMenu.3206177058",
    defaultMessage: "pm"
  }) : React.createElement(FormattedMessage, {
    id: "components.MainMenu.MainMenu.858741075",
    defaultMessage: "am"
  })) : React.createElement("p", {
    className: "alertOpen__textHour"
  }, React.createElement(FormattedMessage, {
    id: "components.MainMenu.MainMenu.2284457695",
    defaultMessage: "Closed establishment"
  }))), React.createElement("div", {
    className: "main-menu__container"
  }, React.createElement("div", {
    className: "main-menu__left"
  }, React.createElement(Media, {
    maxWidth: xxMediumScreen
  }, function (matches) {
    var _user$currentTrade, _user$currentTrade5, _user$currentTrade6;

    return matches ? goback ? React.createElement("ul", {
      className: "main-menu__goback"
    }, React.createElement("li", {
      onClick: goBackCheckout
    }, React.createElement(ReactSVG, {
      path: gobackImg
    }))) : gobackAddress ? React.createElement("ul", {
      className: "main-menu__goback"
    }, React.createElement("li", {
      onClick: goBackAddress
    }, React.createElement(ReactSVG, {
      path: gobackImg
    }))) : gobackStatus ? React.createElement("ul", {
      className: "main-menu__goback"
    }, React.createElement("li", {
      onClick: goBackStatus
    }, React.createElement(ReactSVG, {
      path: gobackImg
    }))) : gobackHome ? React.createElement("ul", {
      className: "main-menu__goback"
    }, React.createElement("li", {
      onClick: function onClick() {
        navigate(baseUrl);
      }
    }, React.createElement(ReactSVG, {
      path: gobackImg
    }))) : gobackAssignedOrders ? React.createElement("ul", {
      className: "main-menu__goback"
    }, React.createElement("li", {
      onClick: function onClick() {
        navigate(baseDeliveryUrl);
      }
    }, React.createElement(ReactSVG, {
      path: gobackImg
    }))) : gobackProduct ? React.createElement("ul", {
      className: "main-menu__goback"
    }, React.createElement("li", {
      onClick: goBackProduct
    }, React.createElement(ReactSVG, {
      path: gobackImg
    }))) : gobackCategoriesSale ? React.createElement("ul", {
      className: "main-menu__goback"
    }, React.createElement("li", {
      onClick: goBackCategoriesSale
    }, React.createElement(ReactSVG, {
      path: gobackImg
    }))) : gobackCategoryRedirectSale ? React.createElement("ul", {
      className: "main-menu__goback"
    }, React.createElement("li", {
      onClick: goBackCategoryRedirectSale
    }, React.createElement(ReactSVG, {
      path: gobackImg
    }))) : gobackSubCategoriesCategory ? React.createElement("ul", {
      className: "main-menu__goback"
    }, React.createElement("li", {
      onClick: goBackSubCategoriesCategory
    }, React.createElement(ReactSVG, {
      path: gobackImg
    }))) : gobackDelivery ? React.createElement("ul", {
      className: "main-menu__goback"
    }, React.createElement("li", {
      onClick: function onClick() {
        return goBackDelivery(currentOrderState);
      }
    }, React.createElement(ReactSVG, {
      path: gobackImg
    }))) : gobackNeighborhoods ? React.createElement("ul", {
      className: "main-menu__goback"
    }, React.createElement("li", {
      onClick: goBackNeighborhoods
    }, React.createElement(ReactSVG, {
      path: gobackImg
    }))) : (user === null || user === void 0 ? void 0 : user.currentTrade) ? React.createElement("ul", null, React.createElement(Media, {
      maxWidth: xlargeScreen
    }, React.createElement("li", {
      "data-test": "toggleSideMenuLink",
      className: "main-menu__hamburger",
      onClick: function onClick() {
        return overlayContext.show(OverlayType.sideNav, OverlayTheme.left, {
          data: []
        });
      }
    }, React.createElement(ReactSVG, {
      path: hamburgerImg,
      className: "main-menu__hamburger--icon"
    })))) : React.createElement(React.Fragment, null) : gobackNeighborhoods ? React.createElement("ul", {
      className: "main-menu__goback"
    }, React.createElement("li", {
      onClick: goBackNeighborhoods
    }, React.createElement(ReactSVG, {
      path: gobackImg
    }))) : React.createElement("div", {
      className: "main-menu__left__trade"
    }, React.createElement(Link, {
      to: deliveryAccess ? appPaths.baseDeliveryUrl : appPaths.baseUrl
    }, differentTradeToActual && tradeLogo ? React.createElement(CachedImage, {
      url: maybe(function () {
        return tradeLogo.url;
      }),
      alt: maybe(function () {
        return tradeLogo.alt ? tradeLogo.alt : "";
      }, "")
    }, React.createElement(React.Fragment, null)) : differentTradeToActual && !tradeLogo ? React.createElement(ReactSVG, {
      path: logoImg
    }) : (user === null || user === void 0 ? void 0 : (_user$currentTrade = user.currentTrade) === null || _user$currentTrade === void 0 ? void 0 : _user$currentTrade.logoImage) ? React.createElement(CachedImage, {
      url: maybe(function () {
        var _ref2, _user$currentTrade2;

        return (_ref2 = user === null || user === void 0 ? void 0 : (_user$currentTrade2 = user.currentTrade) === null || _user$currentTrade2 === void 0 ? void 0 : _user$currentTrade2.logoImage) === null || _ref2 === void 0 ? void 0 : _ref2.url;
      }),
      alt: maybe(function () {
        var _ref3, _user$currentTrade3, _ref4, _user$currentTrade4;

        return ((_ref3 = user === null || user === void 0 ? void 0 : (_user$currentTrade3 = user.currentTrade) === null || _user$currentTrade3 === void 0 ? void 0 : _user$currentTrade3.logoImage) === null || _ref3 === void 0 ? void 0 : _ref3.alt) ? (_ref4 = user === null || user === void 0 ? void 0 : (_user$currentTrade4 = user.currentTrade) === null || _user$currentTrade4 === void 0 ? void 0 : _user$currentTrade4.logoImage) === null || _ref4 === void 0 ? void 0 : _ref4.alt : "";
      }, "")
    }, React.createElement(React.Fragment, null)) : React.createElement(ReactSVG, {
      path: logoImg
    })), React.createElement("div", {
      className: "main-menu__left-number"
    }, differentTradeToActual && tradeName ? React.createElement("h5", null, tradeName) : (user === null || user === void 0 ? void 0 : (_user$currentTrade5 = user.currentTrade) === null || _user$currentTrade5 === void 0 ? void 0 : _user$currentTrade5.name) && React.createElement("h5", null, user === null || user === void 0 ? void 0 : (_user$currentTrade6 = user.currentTrade) === null || _user$currentTrade6 === void 0 ? void 0 : _user$currentTrade6.name)));
  })), React.createElement("div", {
    className: "main-menu__center"
  }, differentTradeToActual && tradeName ? !cartCheckout && ((tradeName === null || tradeName === void 0 ? void 0 : tradeName.length) <= 13 ? React.createElement(Media, {
    maxWidth: xxMediumScreen
  }, React.createElement("h5", null, tradeName)) : (tradeName === null || tradeName === void 0 ? void 0 : tradeName.length) > 13 && (tradeName === null || tradeName === void 0 ? void 0 : tradeName.length) <= 22 ? React.createElement(Media, {
    className: "main-menu__center--name",
    maxWidth: xMediumScreen
  }, React.createElement("h5", null, tradeName)) : React.createElement(Media, {
    maxWidth: xxMediumScreen
  }, React.createElement(Media, {
    minWidth: mediumScreen
  }, React.createElement("h5", null, tradeName)))) : !cartCheckout && ((user === null || user === void 0 ? void 0 : (_user$currentTrade7 = user.currentTrade) === null || _user$currentTrade7 === void 0 ? void 0 : _user$currentTrade7.name) && (user === null || user === void 0 ? void 0 : (_user$currentTrade8 = user.currentTrade) === null || _user$currentTrade8 === void 0 ? void 0 : (_user$currentTrade8$n = _user$currentTrade8.name) === null || _user$currentTrade8$n === void 0 ? void 0 : _user$currentTrade8$n.length) <= 13 ? React.createElement(Media, {
    maxWidth: xxMediumScreen
  }, React.createElement("h5", null, user === null || user === void 0 ? void 0 : (_user$currentTrade9 = user.currentTrade) === null || _user$currentTrade9 === void 0 ? void 0 : _user$currentTrade9.name)) : (user === null || user === void 0 ? void 0 : (_user$currentTrade10 = user.currentTrade) === null || _user$currentTrade10 === void 0 ? void 0 : (_user$currentTrade10$ = _user$currentTrade10.name) === null || _user$currentTrade10$ === void 0 ? void 0 : _user$currentTrade10$.length) > 13 && (user === null || user === void 0 ? void 0 : (_user$currentTrade11 = user.currentTrade) === null || _user$currentTrade11 === void 0 ? void 0 : (_user$currentTrade11$ = _user$currentTrade11.name) === null || _user$currentTrade11$ === void 0 ? void 0 : _user$currentTrade11$.length) <= 22 ? React.createElement(Media, {
    maxWidth: xxMediumScreen
  }, (user === null || user === void 0 ? void 0 : (_user$currentTrade12 = user.currentTrade) === null || _user$currentTrade12 === void 0 ? void 0 : _user$currentTrade12.name) ? React.createElement("h5", {
    className: "main-menu__center--name"
  }, user === null || user === void 0 ? void 0 : (_user$currentTrade13 = user.currentTrade) === null || _user$currentTrade13 === void 0 ? void 0 : _user$currentTrade13.name) : React.createElement("h5", null, " ")) : React.createElement(Media, {
    maxWidth: xxMediumScreen
  }, React.createElement(Media, {
    minWidth: mediumScreen
  }, (user === null || user === void 0 ? void 0 : (_user$currentTrade14 = user.currentTrade) === null || _user$currentTrade14 === void 0 ? void 0 : _user$currentTrade14.name) ? React.createElement("h5", null, user === null || user === void 0 ? void 0 : (_user$currentTrade15 = user.currentTrade) === null || _user$currentTrade15 === void 0 ? void 0 : _user$currentTrade15.name) : React.createElement("h5", null, " ")))), React.createElement(Media, {
    maxWidth: xxMediumScreen
  }, React.createElement(Link, {
    to: deliveryAccess ? appPaths.baseDeliveryUrl : appPaths.baseUrl
  }, differentTradeToActual && tradeLogo ? React.createElement(CachedImage, {
    url: maybe(function () {
      return tradeLogo.url;
    }),
    alt: maybe(function () {
      return tradeLogo.alt ? tradeLogo.alt : "";
    }, "")
  }, React.createElement(React.Fragment, null)) : differentTradeToActual && !tradeLogo ? React.createElement(ReactSVG, {
    path: logoImg
  }) : (user === null || user === void 0 ? void 0 : (_user$currentTrade16 = user.currentTrade) === null || _user$currentTrade16 === void 0 ? void 0 : _user$currentTrade16.logoImage) ? React.createElement(CachedImage, {
    url: maybe(function () {
      var _ref5, _user$currentTrade17;

      return (_ref5 = user === null || user === void 0 ? void 0 : (_user$currentTrade17 = user.currentTrade) === null || _user$currentTrade17 === void 0 ? void 0 : _user$currentTrade17.logoImage) === null || _ref5 === void 0 ? void 0 : _ref5.url;
    }),
    alt: maybe(function () {
      var _ref6, _user$currentTrade18, _ref7, _user$currentTrade19;

      return ((_ref6 = user === null || user === void 0 ? void 0 : (_user$currentTrade18 = user.currentTrade) === null || _user$currentTrade18 === void 0 ? void 0 : _user$currentTrade18.logoImage) === null || _ref6 === void 0 ? void 0 : _ref6.alt) ? (_ref7 = user === null || user === void 0 ? void 0 : (_user$currentTrade19 = user.currentTrade) === null || _user$currentTrade19 === void 0 ? void 0 : _user$currentTrade19.logoImage) === null || _ref7 === void 0 ? void 0 : _ref7.alt : "";
    }, "")
  }, React.createElement(React.Fragment, null)) : React.createElement(ReactSVG, {
    path: logoImg
  })))), React.createElement("div", {
    className: "main-menu__right"
  }, React.createElement("ul", null, !search && React.createElement("li", {
    className: "main-menu__search--search"
  }, " "), React.createElement(Offline, null, React.createElement("li", {
    className: "main-menu__offline"
  }, React.createElement(Media, {
    minWidth: xlargeScreen
  }, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "components.MainMenu.MainMenu.4144939532",
    defaultMessage: "Offline"
  }))))), React.createElement(Online, null, cart ? React.createElement(React.Fragment, null, React.createElement(Media, {
    minWidth: 850
  }, loadingCurrentTradeState ? React.createElement("div", {
    className: "main-menu__skeleton"
  }) : React.createElement("div", null, React.createElement("p", {
    className: "main-menu__priceOrder__textInfoPriceOrder"
  }, intl.formatMessage(checkoutMessages.priceShipping), ":"), parseInt("".concat(deliveryPrice.amount), 10) > 0 ? React.createElement("p", {
    className: "main-menu__priceOrder__amount"
  }, React.createElement(Money, {
    money: deliveryPrice
  })) : React.createElement("p", {
    className: "main-menu__priceOrder__free"
  }, intl.formatMessage(checkoutMessages.shippingFree)))), React.createElement(Media, {
    maxWidth: 850
  }, user && !(user === null || user === void 0 ? void 0 : user.isAnonymousUser) ? React.createElement(NavLink, {
    className: "main-menu__iconMyProfile main-menu__cartMyProfile",
    to: paths.accountUrl
  }, React.createElement(ReactSVG, {
    path: profileImage
  })) : React.createElement("div", {
    className: "main-menu__iconMyProfile main-menu__cartMyProfile",
    onClick: function onClick() {
      return setShowAlertLogin(true);
    }
  }, React.createElement(ReactSVG, {
    path: profileImage
  }))), React.createElement("li", {
    "data-test": "menuCartOverlayLink",
    className: "main-menu__icon main-menu__cart",
    onClick: function onClick() {
      if (user) {
        if (user === null || user === void 0 ? void 0 : user.isAnonymousUser) {
          setShowAlertLogin(true);
        } else {
          overlayContext.show(OverlayType.cart, OverlayTheme.right);
        }
      }
    }
  }, React.createElement(ReactSVG, {
    path: cartImg
  }), cartItemsQuantity > 0 ? React.createElement("span", {
    className: "main-menu__cart__quantity"
  }, cartItemsQuantity) : null)) : cartCheckout && mobileCartOpened !== undefined && setMobileCartOpened !== undefined ? React.createElement("li", {
    "data-test": "menuCartOverlayLink",
    className: "main-menu__icon main-menu__cart",
    onClick: function onClick() {
      if (user) {
        if (user === null || user === void 0 ? void 0 : user.isAnonymousUser) {
          setShowAlertLogin(true);
        } else {
          setMobileCartOpened(!mobileCartOpened);
        }
      }
    }
  }, React.createElement(Media, {
    maxWidth: 850
  }, React.createElement("div", {
    className: "main-menu__icon main-menu__cart__checkout"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.MainMenu.MainMenu.569047032",
    defaultMessage: "detail"
  })), cartItemsQuantity > 0 ? React.createElement("span", {
    className: "main-menu__cart__checkout__quantity"
  }, cartItemsQuantity) : null))) : differentTradeToActual && orderStatus !== undefined && tradeNumber !== undefined && tradeNumber !== null && orderNumber !== undefined && orderNumber !== null && orderStatus.toUpperCase() !== "PENDING" && orderStatus.toUpperCase() !== "TIMEOUT" && orderStatus.toUpperCase() !== "CANCELED" ? React.createElement("li", {
    className: "main-menu__icon main-menu__status",
    onClick: function onClick() {
      return setShowAlertHelp(true);
    }
  }, React.createElement("div", {
    className: "main-menu__icon main-menu__status__help"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.MainMenu.MainMenu.2892062428",
    defaultMessage: "help"
  })), React.createElement(ReactSVG, {
    path: helpImg
  }))) : deliveryAccess && location.pathname.includes("/order/") ? React.createElement("li", {
    className: "main-menu__icon main-menu__delivery",
    onClick: function onClick() {
      return showMap(customerAddress);
    }
  }, (customerAddress === null || customerAddress === void 0 ? void 0 : (_customerAddress$city = customerAddress.city2) === null || _customerAddress$city === void 0 ? void 0 : _customerAddress$city.name) ? React.createElement("div", {
    className: "main-menu__icon main-menu__delivery__map"
  }, React.createElement(ReactSVG, {
    path: locationImg
  }), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.MainMenu.MainMenu.1142698207",
    defaultMessage: "Map"
  }))) : React.createElement("div", {
    className: "main-menu__skeleton"
  })) : React.createElement("li", {
    className: "main-menu__icon main-menu__cart--cart"
  }, " ")), React.createElement(Media, {
    minWidth: 850
  }, React.createElement("li", {
    className: "main-menu__profile",
    id: "profile"
  }, React.createElement("div", {
    className: "main-menu__profile--show",
    onClick: function onClick() {
      if (user) {
        if (user === null || user === void 0 ? void 0 : user.isAnonymousUser) {
          setShowAlertLogin(true);
        } else {
          setShowProfileDetail(!showProfileDetail);
        }
      }
    }
  }, React.createElement(ReactSVG, {
    path: profileWhiteImg
  }), !user || (user === null || user === void 0 ? void 0 : user.isAnonymousUser) ? React.createElement("p", null, "An\xF3nimo") : React.createElement("p", null, firstName[0] || "Sin nombre"), user && !(user === null || user === void 0 ? void 0 : user.isAnonymousUser) && React.createElement("div", {
    className: "main-menu__profile--show--arrow"
  }, React.createElement(ReactSVG, {
    path: whiteArrowImg
  }))), showProfileDetail && React.createElement("div", {
    className: "main-menu__profile--detail"
  }, React.createElement(ReactSVG, {
    path: profileImg
  }), React.createElement(NavLink, {
    className: "main-menu__profile--myProfile",
    to: paths.accountUrl
  }, React.createElement(FormattedMessage, {
    id: "components.MainMenu.MainMenu.1028973038",
    defaultMessage: "My Profile"
  })), React.createElement("p", {
    className: "main-menu__profile--detail--name"
  }, user === null || user === void 0 ? void 0 : user.firstName), React.createElement("p", {
    className: "main-menu__profile--detail--number"
  }, (user === null || user === void 0 ? void 0 : user.waNumber) || (user === null || user === void 0 ? void 0 : user.waDaliveryManNumber)), React.createElement("button", {
    onClick: handleSignOut
  }, React.createElement(FormattedMessage, {
    id: "components.MainMenu.MainMenu.69198883",
    defaultMessage: "Sign off"
  }))))))))), search && React.createElement(Media, {
    maxWidth: xMediumScreen
  }, React.createElement(SearchHeaderMobile, {
    loadingDeliveryPrice: loadingCurrentTradeState,
    activeGoBack: goback || gobackAddress || gobackStatus || gobackHome || gobackProduct || gobackCategoriesSale || gobackCategoryRedirectSale || gobackSubCategoriesCategory,
    handleGoBack: goback ? goBackCheckout : gobackAddress ? goBackAddress : gobackStatus ? goBackStatus : gobackHome ? function () {
      navigate(baseUrl);
    } : gobackProduct ? goBackProduct : gobackCategoriesSale ? goBackCategoriesSale : gobackCategoryRedirectSale ? goBackCategoryRedirectSale : gobackSubCategoriesCategory ? goBackSubCategoriesCategory : function () {},
    name: searchDeliveryName
  }))), showAlertHelp && React.createElement(AlertHelp, {
    hideModal: function hideModal() {
      return setShowAlertHelp(false);
    },
    tradeNumber: tradeNumber,
    orderNumber: orderNumber
  }), showAlertMap && React.createElement(AlertMap, {
    hideModal: function hideModal() {
      return setShowAlertMap(false);
    },
    googleMapsURL: googleMapsUrl,
    wazeURL: wazeUrl
  }), showAlertLogin && React.createElement(AlertLogin, {
    hideModal: function hideModal() {
      return setShowAlertLogin(false);
    }
  }));
});
MainMenu.displayName = "MainMenu";
export default MainMenu;